export const POLITICAS_Y_PROTECCION = [
    {
        title: "POLÍTICA DE CONFIDENCIALIDAD Y PROTECCIÓN DE DATOS", content: "Valoramos la confianza que depositas en nosotros al utilizar nuestros productos y queremos que estés al tanto de como manejamos tus datos personales.",
    }, {
        title: "", content: " Mediante la utilización de los sitios y los servicios digitales de Sudamericana Seguros, aceptas nuestra política de Privacidad. En caso de no estar de acuerdo con la misma, por favor, no utilices estos Sitios. Si vos utilizas algún Sitio luego de que se haya introducido alguna modificación a esta política, ello importará la aceptación de los cambios."
    }, {
        title: "", content: " Desde Sudamericana Seguros recolectamos tu información personal para que puedas disfrutar de nuestros servicios y mejorarlos de manera continua para que puedas animarte a más. Recibimos y almacenamos tu información personal que facilitas vos mismo cuando utilizas o adquirís alguno de nuestros servicios o productos."
    }, {
        title: "",
        content: "Acorde a la relación que el titular tenga con nosotros, la información que recopilamos o almacenamos puede revestir el siguiente carácter:"
    }, {
        title: "",
        content: "Datos privados: son aquellos que por su naturaleza íntima o reservada sólo son relevantes para el titular. Por ejemplo: nivel de ingresos, datos financieros, capacidad de endeudamiento, patrimonio bruto, personas a cargo, composición del grupo familiar, hobbies o aficiones, bienes que posee, información laboral, preferencias en redes sociales, hábitos de conducción, hábitos de consumo, así como datos de contacto como dirección, teléfono y correo electrónico personal."
    }, {
        title: "",
        content: "Datos sensibles: son aquellos datos que se reducen a la categoría más íntima y sensible de su titular, cuyo tratamiento inadecuado puede conllevar a la discriminación y/o al sufrimiento de un perjuicio grave y de difícil reparación. Por ejemplo: datos biométricos, historia clínica o datos relacionados con la salud en general."
    }, {
        title: "",
        content: "Datos públicos: son los datos que no son privados o sensibles. Por ejemplo: nombre, tipo y número de documento de identidad, identificación tributaria o previsional, estado civil, profesión, ocupación, fecha de nacimiento, domicilio, información de público conocimiento o que figura en registros públicos."
    },
    {
        title: "",
        content: "Nuestra política se aplica solo a los Sitios y servicios digitales propiedad de Sudamericana Seguros, no así a los sitios a los que vos accedas por medio de links. Te sugerimos que en estos casos te informes acerca de la política adoptada en cada uno de los sitios."
    }, {
        title: "",
        content: "Desde Sudamericana Seguros resguardamos a salvo y conforme a niveles de protección adecuados, toda la información que los visitantes provean. Desde Sudamericana Seguros recolectamos tu información personal para que puedas disfrutar de nuestros servicios y mejorarlos de manera continua para que puedas animarte a más. Podremos utilizar la información provista por vos con fines promocionales, publicitarios y/o estadísticos. Implementamos medidas de seguridad administrativas, técnicas y físicas para proteger tus datos personales ante la destrucción, pérdida, alteración, acceso, comunicación o uso accidental, ilegal o no autorizado. Tenes la facultad de ejercer el derecho de acceso a tus datos en forma gratuita a intervalos no inferiores a seis meses, salvo que se acredite un interés legítimo al efecto conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326."
    }
]