import React from 'react'
import wp from "../../assets/whatsapp.png";
import "../../assets/styles/about.css"
import { LINKS } from '../../constants/external_links';

const AboutUs = () => {
    return (
        <div>
            <p className="text-color-sura text-about" >
                ¿Tenés que hacer un trámite? Es más simple en nuestro WhatsApp:
            </p>
            <div className="button__contact" onClick={() => window.open(LINKS.WHATSAPP_LINK, '_blank')}>
                <img
                    className="image-whatsapp"
                    src={wp}
                    alt="WhatsApp"
                />
            </div>
            <p className='text-about text-color-sura'>
                También podés llamarnos al
            </p>
            <p className='phone-number'>
                0800-222-2772
            </p>
            <p className='text-contact-hour'>
                De lunes a viernes de 9 a 18hs.
            </p>
            <p className='text-about text-color-sura'>
             Para asistencias:
            </p>
            <p className='phone-number'>
                0800-999-76925
            </p>
            <p className='text-contact-hour'>
                las 24 horas los 365 días del año
            </p>
        </div>
    )
}

export default AboutUs
