

export const LIST_PROVINCES = [
    { value: 0, label: "Provincias"},
    { value: 1, label: 'Buenos Aires' },
    { value: 2, label: 'Buenos Aires-GBA' },
    { value: 3, label: 'Capital Federal' },
    { value: 4, label: 'Catamarca' },
    { value: 5, label: 'Chaco' },
    { value: 6, label: 'Chubut' },
    { value: 7, label: 'Córdoba' },
    { value: 8, label: 'Corrientes' },
    { value: 9, label: 'Entre Ríos' },
    { value: 10, label: 'Formosa' },
    { value: 11, label: 'Jujuy' },
    { value: 12, label: 'La Pampa' },
    { value: 13, label: 'La Rioja' },
    { value: 14, label: 'Mendoza' },
    { value: 15, label: 'Misiones' },
    { value: 16, label: 'Neuquén' },
    { value: 17, label: 'Río Negro' },
    { value: 18, label: 'Salta' },
    { value: 19, label: 'San Juan' },
    { value: 20, label: 'San Luis' },
    { value: 21, label: 'Santa Cruz' },
    { value: 22, label: 'Santa Fe' },
    { value: 23, label: 'Santiago del Estero' },
    { value: 24, label: 'Tierra del Fuego' },
    { value: 25, label: 'Tucumán' }
];

export const LIST_TOWNS = [
    { value: 0, codProv: 0, label: "Localidades" },
    { value: 1, codProv: 1, label: "25 de Mayo" },
    { value: 2, codProv: 1, label: "3 de febrero" },
    { value: 3, codProv: 1, label: "A. Alsina" },
    { value: 4, codProv: 1, label: "A. Gonzáles Cháves" },
    { value: 5, codProv: 1, label: "Aguas Verdes" },
    { value: 6, codProv: 1, label: "Alberti" },
    { value: 7, codProv: 1, label: "Arrecifes" },
    { value: 8, codProv: 1, label: "Ayacucho" },
    { value: 9, codProv: 1, label: "Azul" },
    { value: 10, codProv: 1, label: "Bahía Blanca" },
    { value: 11, codProv: 1, label: "Balcarce" },
    { value: 12, codProv: 1, label: "Baradero" },
    { value: 13, codProv: 1, label: "Benito Juárez" },
    { value: 14, codProv: 1, label: "Berisso" },
    { value: 15, codProv: 1, label: "Bolívar" },
    { value: 16, codProv: 1, label: "Bragado" },
    { value: 17, codProv: 1, label: "Brandsen" },
    { value: 18, codProv: 1, label: "Campana" },
    { value: 19, codProv: 1, label: "Cañuelas" },
    { value: 20, codProv: 1, label: "Capilla del Señor" },
    { value: 21, codProv: 1, label: "Capitán Sarmiento" },
    { value: 22, codProv: 1, label: "Carapachay" },
    { value: 23, codProv: 1, label: "Carhue" },
    { value: 24, codProv: 1, label: "Cariló" },
    { value: 25, codProv: 1, label: "Carlos Casares" },
    { value: 26, codProv: 1, label: "Carlos Tejedor" },
    { value: 27, codProv: 1, label: "Carmen de Areco" },
    { value: 28, codProv: 1, label: "Carmen de Patagones" },
    { value: 29, codProv: 1, label: "Castelli" },
    { value: 30, codProv: 1, label: "Chacabuco" },
    { value: 31, codProv: 1, label: "Chascomús" },
    { value: 32, codProv: 1, label: "Chivilcoy" },
    { value: 33, codProv: 1, label: "Colón" },
    { value: 34, codProv: 1, label: "Coronel Dorrego" },
    { value: 35, codProv: 1, label: "Coronel Pringles" },
    { value: 36, codProv: 1, label: "Coronel Rosales" },
    { value: 37, codProv: 1, label: "Coronel Suarez" },
    { value: 38, codProv: 1, label: "Costa Azul" },
    { value: 39, codProv: 1, label: "Costa Chica" },
    { value: 40, codProv: 1, label: "Costa del Este" },
    { value: 41, codProv: 1, label: "Costa Esmeralda" },
    { value: 42, codProv: 1, label: "Daireaux" },
    { value: 43, codProv: 1, label: "Darregueira" },
    { value: 44, codProv: 1, label: "Del Viso" },
    { value: 45, codProv: 1, label: "Dolores" },
    { value: 46, codProv: 1, label: "Don Torcuato" },
    { value: 47, codProv: 1, label: "Ensenada" },
    { value: 48, codProv: 1, label: "Escobar" },
    { value: 49, codProv: 1, label: "Exaltación de la Cruz" },
    { value: 50, codProv: 1, label: "Florentino Ameghino" },
    { value: 51, codProv: 1, label: "Garín" },
    { value: 52, codProv: 1, label: "Gral. Alvarado" },
    { value: 53, codProv: 1, label: "Gral. Alvear" },
    { value: 54, codProv: 1, label: "Gral. Arenales" },
    { value: 55, codProv: 1, label: "Gral. Belgrano" },
    { value: 56, codProv: 1, label: "Gral. Guvalueo" },
    { value: 57, codProv: 1, label: "Gral. Lamadrvalue" },
    { value: 58, codProv: 1, label: "Gral. Las Heras" },
    { value: 59, codProv: 1, label: "Gral. Lavalle" },
    { value: 60, codProv: 1, label: "Gral. Madariaga" },
    { value: 61, codProv: 1, label: "Gral. Pacheco" },
    { value: 62, codProv: 1, label: "Gral. Paz" },
    { value: 63, codProv: 1, label: "Gral. Pinto" },
    { value: 64, codProv: 1, label: "Gral. Pueyrredón" },
    { value: 65, codProv: 1, label: "Gral. Rodríguez" },
    { value: 66, codProv: 1, label: "Gral. Viamonte" },
    { value: 67, codProv: 1, label: "Gral. Villegas" },
    { value: 68, codProv: 1, label: "Guaminí" },
    { value: 69, codProv: 1, label: "Guernica" },
    { value: 70, codProv: 1, label: "Hipólito Yrigoyen" },
    { value: 71, codProv: 1, label: "Ing. Maschwitz" },
    { value: 72, codProv: 1, label: "Junín" },
    { value: 73, codProv: 1, label: "La Plata" },
    { value: 74, codProv: 1, label: "Laprvaluea" },
    { value: 75, codProv: 1, label: "Las Flores" },
    { value: 76, codProv: 1, label: "Las Toninas" },
    { value: 77, codProv: 1, label: "Leandro N. Alem" },
    { value: 78, codProv: 1, label: "Lincoln" },
    { value: 79, codProv: 1, label: "Loberia" },
    { value: 80, codProv: 1, label: "Lobos" },
    { value: 81, codProv: 1, label: "Los Cardales" },
    { value: 82, codProv: 1, label: "Los Toldos" },
    { value: 83, codProv: 1, label: "Lucila del Mar" },
    { value: 84, codProv: 1, label: "Luján" },
    { value: 85, codProv: 1, label: "Magdalena" },
    { value: 86, codProv: 1, label: "Maipú" },
    { value: 87, codProv: 1, label: "Mar Chiquita" },
    { value: 88, codProv: 1, label: "Mar de Ajó" },
    { value: 89, codProv: 1, label: "Mar de las Pampas" },
    { value: 90, codProv: 1, label: "Mar del Plata" },
    { value: 91, codProv: 1, label: "Mar del Tuyú" },
    { value: 92, codProv: 1, label: "Marcos Paz" },
    { value: 93, codProv: 1, label: "Mercedes" },
    { value: 94, codProv: 1, label: "Miramar" },
    { value: 95, codProv: 1, label: "Monte" },
    { value: 96, codProv: 1, label: "Monte Hermoso" },
    { value: 97, codProv: 1, label: "Munro" },
    { value: 98, codProv: 1, label: "Navarro" },
    { value: 99, codProv: 1, label: "Necochea" },
    { value: 100, codProv: 1, label: "Olavarría" },
    { value: 101, codProv: 1, label: "Partvalueo de la Costa" },
    { value: 102, codProv: 1, label: "Pehuajó" },
    { value: 103, codProv: 1, label: "Pellegrini" },
    { value: 104, codProv: 1, label: "Pergamino" },
    { value: 105, codProv: 1, label: "Pigüé" },
    { value: 106, codProv: 1, label: "Pila" },
    { value: 107, codProv: 1, label: "Pilar" },
    { value: 108, codProv: 1, label: "Pinamar" },
    { value: 109, codProv: 1, label: "Pinar del Sol" },
    { value: 110, codProv: 1, label: "Polvorines" },
    { value: 111, codProv: 1, label: "Pte. Perón" },
    { value: 112, codProv: 1, label: "Puán" },
    { value: 113, codProv: 1, label: "Punta Indio" },
    { value: 114, codProv: 1, label: "Ramallo" },
    { value: 115, codProv: 1, label: "Rauch" },
    { value: 116, codProv: 1, label: "Rivadavia" },
    { value: 117, codProv: 1, label: "Rojas" },
    { value: 118, codProv: 1, label: "Roque Pérez" },
    { value: 119, codProv: 1, label: "Saavedra" },
    { value: 120, codProv: 1, label: "Saladillo" },
    { value: 121, codProv: 1, label: "Salliqueló" },
    { value: 122, codProv: 1, label: "Salto" },
    { value: 123, codProv: 1, label: "San Andrés de Giles" },
    { value: 124, codProv: 1, label: "San Antonio de Areco" },
    { value: 125, codProv: 1, label: "San Antonio de Padua" },
    { value: 126, codProv: 1, label: "San Bernardo" },
    { value: 127, codProv: 1, label: "San Cayetano" },
    { value: 128, codProv: 1, label: "San Clemente del Tuyú" },
    { value: 129, codProv: 1, label: "San Nicolás" },
    { value: 130, codProv: 1, label: "San Pedro" },
    { value: 131, codProv: 1, label: "San Vicente" },
    { value: 132, codProv: 1, label: "Santa Teresita" },
    { value: 133, codProv: 1, label: "Suipacha" },
    { value: 134, codProv: 1, label: "Tandil" },
    { value: 135, codProv: 1, label: "Tapalqué" },
    { value: 136, codProv: 1, label: "Tordillo" },
    { value: 137, codProv: 1, label: "Tornquist" },
    { value: 138, codProv: 1, label: "Trenque Lauquen" },
    { value: 139, codProv: 1, label: "Tres Lomas" },
    { value: 140, codProv: 1, label: "Villa Gesell" },
    { value: 141, codProv: 1, label: "Villarino" },
    { value: 142, codProv: 1, label: "Zárate" },
    { value: 143, codProv: 2, label: "11 de Septiembre" },
    { value: 144, codProv: 2, label: "20 de Junio" },
    { value: 145, codProv: 2, label: "25 de Mayo" },
    { value: 146, codProv: 2, label: "Acassuso" },
    { value: 147, codProv: 2, label: "Adrogué" },
    { value: 148, codProv: 2, label: "Aldo Bonzi" },
    { value: 149, codProv: 2, label: "Área Reserva Cinturón Ecológico" },
    { value: 150, codProv: 2, label: "Avellaneda" },
    { value: 151, codProv: 2, label: "Banfield" },
    { value: 152, codProv: 2, label: "Barrio Parque" },
    { value: 153, codProv: 2, label: "Barrio Santa Teresita" },
    { value: 154, codProv: 2, label: "Beccar" },
    { value: 155, codProv: 2, label: "Bella Vista" },
    { value: 156, codProv: 2, label: "Berazategui" },
    { value: 157, codProv: 2, label: "Bernal Este" },
    { value: 158, codProv: 2, label: "Bernal Oeste" },
    { value: 159, codProv: 2, label: "Billinghurst" },
    { value: 160, codProv: 2, label: "Boulogne" },
    { value: 161, codProv: 2, label: "Burzaco" },
    { value: 162, codProv: 2, label: "Carapachay" },
    { value: 163, codProv: 2, label: "Caseros" },
    { value: 164, codProv: 2, label: "Castelar" },
    { value: 165, codProv: 2, label: "Churruca" },
    { value: 166, codProv: 2, label: "Ciudad Evita" },
    { value: 167, codProv: 2, label: "Ciudad Madero" },
    { value: 168, codProv: 2, label: "Ciudadela" },
    { value: 169, codProv: 2, label: "Claypole" },
    { value: 170, codProv: 2, label: "Crucecita" },
    { value: 171, codProv: 2, label: "Dock Sud" },
    { value: 172, codProv: 2, label: "Don Bosco" },
    { value: 173, codProv: 2, label: "Don Orione" },
    { value: 174, codProv: 2, label: "El Jagüel" },
    { value: 175, codProv: 2, label: "El Libertador" },
    { value: 176, codProv: 2, label: "El Palomar" },
    { value: 177, codProv: 2, label: "El Tala" },
    { value: 178, codProv: 2, label: "El Trébol" },
    { value: 179, codProv: 2, label: "Ezeiza" },
    { value: 180, codProv: 2, label: "Ezpeleta" },
    { value: 181, codProv: 2, label: "Florencio Varela" },
    { value: 182, codProv: 2, label: "Florvaluea" },
    { value: 183, codProv: 2, label: "Francisco Álvarez" },
    { value: 184, codProv: 2, label: "Gerli" },
    { value: 185, codProv: 2, label: "Glew" },
    { value: 186, codProv: 2, label: "González Catán" },
    { value: 187, codProv: 2, label: "Gral. Lamadrvalue" },
    { value: 188, codProv: 2, label: "Grand Bourg" },
    { value: 189, codProv: 2, label: "Gregorio de Laferrere" },
    { value: 190, codProv: 2, label: "Guillermo Enrique Hudson" },
    { value: 191, codProv: 2, label: "Haedo" },
    { value: 192, codProv: 2, label: "Hurlingham" },
    { value: 193, codProv: 2, label: "Ing. Sourdeaux" },
    { value: 194, codProv: 2, label: "Isvaluero Casanova" },
    { value: 195, codProv: 2, label: "Ituzaingó" },
    { value: 196, codProv: 2, label: "José C. Paz" },
    { value: 197, codProv: 2, label: "José Ingenieros" },
    { value: 198, codProv: 2, label: "José Marmol" },
    { value: 199, codProv: 2, label: "La Lucila" },
    { value: 200, codProv: 2, label: "La Reja" },
    { value: 201, codProv: 2, label: "La Tablada" },
    { value: 202, codProv: 2, label: "Lanús" },
    { value: 203, codProv: 2, label: "Llavallol" },
    { value: 204, codProv: 2, label: "Loma Hermosa" },
    { value: 205, codProv: 2, label: "Lomas de Zamora" },
    { value: 206, codProv: 2, label: "Lomas del Millón" },
    { value: 207, codProv: 2, label: "Lomas del Mirador" },
    { value: 208, codProv: 2, label: "Longchamps" },
    { value: 209, codProv: 2, label: "Los Polvorines" },
    { value: 210, codProv: 2, label: "Luis Guillón" },
    { value: 211, codProv: 2, label: "Malvinas Argentinas" },
    { value: 212, codProv: 2, label: "Martín Coronado" },
    { value: 213, codProv: 2, label: "Martínez" },
    { value: 214, codProv: 2, label: "Merlo" },
    { value: 215, codProv: 2, label: "Ministro Rivadavia" },
    { value: 216, codProv: 2, label: "Monte Chingolo" },
    { value: 217, codProv: 2, label: "Monte Grande" },
    { value: 218, codProv: 2, label: "Moreno" },
    { value: 219, codProv: 2, label: "Morón" },
    { value: 220, codProv: 2, label: "Muñiz" },
    { value: 221, codProv: 2, label: "Olivos" },
    { value: 222, codProv: 2, label: "Pablo Nogués" },
    { value: 223, codProv: 2, label: "Pablo Podestá" },
    { value: 224, codProv: 2, label: "Paso del Rey" },
    { value: 225, codProv: 2, label: "Pereyra" },
    { value: 226, codProv: 2, label: "Piñeiro" },
    { value: 227, codProv: 2, label: "Plátanos" },
    { value: 228, codProv: 2, label: "Pontevedra" },
    { value: 229, codProv: 2, label: "Quilmes" },
    { value: 230, codProv: 2, label: "Rafael Calzada" },
    { value: 231, codProv: 2, label: "Rafael Castillo" },
    { value: 232, codProv: 2, label: "Ramos Mejía" },
    { value: 233, codProv: 2, label: "Ranelagh" },
    { value: 234, codProv: 2, label: "Remedios de Escalada" },
    { value: 235, codProv: 2, label: "Sáenz Peña" },
    { value: 236, codProv: 2, label: "San Antonio de Padua" },
    { value: 237, codProv: 2, label: "San Fernando" },
    { value: 238, codProv: 2, label: "San Francisco Solano" },
    { value: 239, codProv: 2, label: "San Isvaluero" },
    { value: 240, codProv: 2, label: "San José" },
    { value: 241, codProv: 2, label: "San Justo" },
    { value: 242, codProv: 2, label: "San Martín" },
    { value: 243, codProv: 2, label: "San Miguel" },
    { value: 244, codProv: 2, label: "Santos Lugares" },
    { value: 245, codProv: 2, label: "Sarandí" },
    { value: 246, codProv: 2, label: "Sourigues" },
    { value: 247, codProv: 2, label: "Tapiales" },
    { value: 248, codProv: 2, label: "Temperley" },
    { value: 249, codProv: 2, label: "Tigre" },
    { value: 250, codProv: 2, label: "Tortuguitas" },
    { value: 251, codProv: 2, label: "Tristán Suárez" },
    { value: 252, codProv: 2, label: "Trujui" },
    { value: 253, codProv: 2, label: "Turdera" },
    { value: 254, codProv: 2, label: "Valentín Alsina" },
    { value: 255, codProv: 2, label: "Vicente López" },
    { value: 256, codProv: 2, label: "Villa Adelina" },
    { value: 257, codProv: 2, label: "Villa Ballester" },
    { value: 258, codProv: 2, label: "Villa Bosch" },
    { value: 259, codProv: 2, label: "Villa Caraza" },
    { value: 260, codProv: 2, label: "Villa Celina" },
    { value: 261, codProv: 2, label: "Villa Centenario" },
    { value: 262, codProv: 2, label: "Villa de Mayo" },
    { value: 263, codProv: 2, label: "Villa Diamante" },
    { value: 264, codProv: 2, label: "Villa Domínico" },
    { value: 265, codProv: 2, label: "Villa España" },
    { value: 266, codProv: 2, label: "Villa Fiorito" },
    { value: 267, codProv: 2, label: "Villa Guillermina" },
    { value: 268, codProv: 2, label: "Villa Insuperable" },
    { value: 269, codProv: 2, label: "Villa José León Suárez" },
    { value: 270, codProv: 2, label: "Villa La Florvaluea" },
    { value: 271, codProv: 2, label: "Villa Luzuriaga" },
    { value: 272, codProv: 2, label: "Villa Martelli" },
    { value: 273, codProv: 2, label: "Villa Obrera" },
    { value: 274, codProv: 2, label: "Villa Progreso" },
    { value: 275, codProv: 2, label: "Villa Raffo" },
    { value: 276, codProv: 2, label: "Villa Sarmiento" },
    { value: 277, codProv: 2, label: "Villa Tesei" },
    { value: 278, codProv: 2, label: "Villa Udaondo" },
    { value: 279, codProv: 2, label: "Virrey del Pino" },
    { value: 280, codProv: 2, label: "Wilde" },
    { value: 281, codProv: 2, label: "William Morris" },
    { value: 282, codProv: 3, label: "Agronomía" },
    { value: 283, codProv: 3, label: "Almagro" },
    { value: 284, codProv: 3, label: "Balvanera" },
    { value: 285, codProv: 3, label: "Barracas" },
    { value: 286, codProv: 3, label: "Belgrano" },
    { value: 287, codProv: 3, label: "Boca" },
    { value: 288, codProv: 3, label: "Boedo" },
    { value: 289, codProv: 3, label: "Caballito" },
    { value: 290, codProv: 3, label: "Chacarita" },
    { value: 291, codProv: 3, label: "Coghlan" },
    { value: 292, codProv: 3, label: "Colegiales" },
    { value: 293, codProv: 3, label: "Constitución" },
    { value: 294, codProv: 3, label: "Flores" },
    { value: 295, codProv: 3, label: "Floresta" },
    { value: 296, codProv: 3, label: "La Paternal" },
    { value: 297, codProv: 3, label: "Liniers" },
    { value: 298, codProv: 3, label: "Mataderos" },
    { value: 299, codProv: 3, label: "Monserrat" },
    { value: 300, codProv: 3, label: "Monte Castro" },
    { value: 301, codProv: 3, label: "Nueva Pompeya" },
    { value: 302, codProv: 3, label: "Núñez" },
    { value: 303, codProv: 3, label: "Palermo" },
    { value: 304, codProv: 3, label: "Parque Avellaneda" },
    { value: 305, codProv: 3, label: "Parque Chacabuco" },
    { value: 306, codProv: 3, label: "Parque Chas" },
    { value: 307, codProv: 3, label: "Parque Patricios" },
    { value: 308, codProv: 3, label: "Puerto Madero" },
    { value: 309, codProv: 3, label: "Recoleta" },
    { value: 310, codProv: 3, label: "Retiro" },
    { value: 311, codProv: 3, label: "Saavedra" },
    { value: 312, codProv: 3, label: "San Cristóbal" },
    { value: 313, codProv: 3, label: "San Nicolás" },
    { value: 314, codProv: 3, label: "San Telmo" },
    { value: 315, codProv: 3, label: "Vélez Sársfield" },
    { value: 316, codProv: 3, label: "Versalles" },
    { value: 317, codProv: 3, label: "Villa Crespo" },
    { value: 318, codProv: 3, label: "Villa del Parque" },
    { value: 319, codProv: 3, label: "Villa Devoto" },
    { value: 320, codProv: 3, label: "Villa Gral. Mitre" },
    { value: 321, codProv: 3, label: "Villa Lugano" },
    { value: 322, codProv: 3, label: "Villa Luro" },
    { value: 323, codProv: 3, label: "Villa Ortúzar" },
    { value: 324, codProv: 3, label: "Villa Pueyrredón" },
    { value: 325, codProv: 3, label: "Villa Real" },
    { value: 326, codProv: 3, label: "Villa Riachuelo" },
    { value: 327, codProv: 3, label: "Villa Santa Rita" },
    { value: 328, codProv: 3, label: "Villa Soldati" },
    { value: 329, codProv: 3, label: "Villa Urquiza" },
    { value: 330, codProv: 4, label: "Aconquija" },
    { value: 331, codProv: 4, label: "Ancasti" },
    { value: 332, codProv: 4, label: "Andalgalá" },
    { value: 333, codProv: 4, label: "Antofagasta" },
    { value: 334, codProv: 4, label: "Belén" },
    { value: 335, codProv: 4, label: "Capayán" },
    { value: 336, codProv: 4, label: "Capital" },
    { value: 338, codProv: 4, label: "Corral Quemado" },
    { value: 339, codProv: 4, label: "El Alto" },
    { value: 340, codProv: 4, label: "El Rodeo" },
    { value: 341, codProv: 4, label: "F.Mamerto Esquiú" },
    { value: 342, codProv: 4, label: "Fiambalá" },
    { value: 343, codProv: 4, label: "Hualfín" },
    { value: 344, codProv: 4, label: "Huillapima" },
    { value: 345, codProv: 4, label: "Icaño" },
    { value: 346, codProv: 4, label: "La Puerta" },
    { value: 347, codProv: 4, label: "Las Juntas" },
    { value: 348, codProv: 4, label: "Londres" },
    { value: 349, codProv: 4, label: "Los Altos" },
    { value: 350, codProv: 4, label: "Los Varela" },
    { value: 351, codProv: 4, label: "Mutquín" },
    { value: 352, codProv: 4, label: "Paclín" },
    { value: 353, codProv: 4, label: "Poman" },
    { value: 354, codProv: 4, label: "Pozo de La Piedra" },
    { value: 355, codProv: 4, label: "Puerta de Corral" },
    { value: 356, codProv: 4, label: "Puerta San José" },
    { value: 357, codProv: 4, label: "Recreo" },
    { value: 358, codProv: 4, label: "S.F.V de 4" },
    { value: 359, codProv: 4, label: "San Fernando" },
    { value: 360, codProv: 4, label: "San Fernando del Valle" },
    { value: 361, codProv: 4, label: "San José" },
    { value: 362, codProv: 4, label: "Santa María" },
    { value: 363, codProv: 4, label: "Santa Rosa" },
    { value: 364, codProv: 4, label: "Saujil" },
    { value: 365, codProv: 4, label: "Tapso" },
    { value: 366, codProv: 4, label: "Tinogasta" },
    { value: 367, codProv: 4, label: "Valle Viejo" },
    { value: 368, codProv: 4, label: "Villa Vil" },
    { value: 369, codProv: 5, label: "Aviá Teraí" },
    { value: 370, codProv: 5, label: "Barranqueras" },
    { value: 371, codProv: 5, label: "Basail" },
    { value: 372, codProv: 5, label: "Campo Largo" },
    { value: 373, codProv: 5, label: "Capital" },
    { value: 374, codProv: 5, label: "Capitán Solari" },
    { value: 375, codProv: 5, label: "Charadai" },
    { value: 376, codProv: 5, label: "Charata" },
    { value: 377, codProv: 5, label: "Chorotis" },
    { value: 378, codProv: 5, label: "Ciervo Petiso" },
    { value: 379, codProv: 5, label: "Cnel. Du Graty" },
    { value: 380, codProv: 5, label: "Col. Benítez" },
    { value: 381, codProv: 5, label: "Col. Elisa" },
    { value: 382, codProv: 5, label: "Col. Popular" },
    { value: 383, codProv: 5, label: "Colonias Unvalueas" },
    { value: 384, codProv: 5, label: "Concepción" },
    { value: 385, codProv: 5, label: "Corzuela" },
    { value: 386, codProv: 5, label: "Cote Lai" },
    { value: 387, codProv: 5, label: "El Sauzalito" },
    { value: 388, codProv: 5, label: "Enrique Urien" },
    { value: 389, codProv: 5, label: "Fontana" },
    { value: 390, codProv: 5, label: "Fte. Esperanza" },
    { value: 391, codProv: 5, label: "Gancedo" },
    { value: 392, codProv: 5, label: "Gral. Capdevila" },
    { value: 393, codProv: 5, label: "Gral. Pinero" },
    { value: 394, codProv: 5, label: "Gral. San Martín" },
    { value: 395, codProv: 5, label: "Gral. Vedia" },
    { value: 396, codProv: 5, label: "Hermoso Campo" },
    { value: 397, codProv: 5, label: "I. del Cerrito" },
    { value: 398, codProv: 5, label: "J.J. Castelli" },
    { value: 399, codProv: 5, label: "La Clotilde" },
    { value: 400, codProv: 5, label: "La Eduvigis" },
    { value: 401, codProv: 5, label: "La Escondvaluea" },
    { value: 402, codProv: 5, label: "La Leonesa" },
    { value: 403, codProv: 5, label: "La Tigra" },
    { value: 404, codProv: 5, label: "La Verde" },
    { value: 405, codProv: 5, label: "Laguna Blanca" },
    { value: 406, codProv: 5, label: "Laguna Limpia" },
    { value: 407, codProv: 5, label: "Lapachito" },
    { value: 408, codProv: 5, label: "Las Breñas" },
    { value: 409, codProv: 5, label: "Las Garcitas" },
    { value: 410, codProv: 5, label: "Las Palmas" },
    { value: 411, codProv: 5, label: "Los Frentones" },
    { value: 412, codProv: 5, label: "Machagai" },
    { value: 413, codProv: 5, label: "Makallé" },
    { value: 414, codProv: 5, label: "Margarita Belén" },
    { value: 415, codProv: 5, label: "Miraflores" },
    { value: 416, codProv: 5, label: "Misión N. Pompeya" },
    { value: 417, codProv: 5, label: "Napenay" },
    { value: 418, codProv: 5, label: "Pampa Almirón" },
    { value: 419, codProv: 5, label: "Pampa del Indio" },
    { value: 420, codProv: 5, label: "Pampa del Infierno" },
    { value: 421, codProv: 5, label: "Pdcia. de La Plaza" },
    { value: 422, codProv: 5, label: "Pdcia. Roca" },
    { value: 423, codProv: 5, label: "Pdcia. Roque Sáenz Peña" },
    { value: 424, codProv: 5, label: "Pto. Bermejo" },
    { value: 425, codProv: 5, label: "Pto. Eva Perón" },
    { value: 426, codProv: 5, label: "Puero Tirol" },
    { value: 427, codProv: 5, label: "Puerto Vilelas" },
    { value: 428, codProv: 5, label: "Quitilipi" },
    { value: 429, codProv: 5, label: "Resistencia" },
    { value: 430, codProv: 5, label: "Sáenz Peña" },
    { value: 431, codProv: 5, label: "Samuhú" },
    { value: 432, codProv: 5, label: "San Bernardo" },
    { value: 433, codProv: 5, label: "Santa Sylvina" },
    { value: 434, codProv: 5, label: "Taco Pozo" },
    { value: 435, codProv: 5, label: "Tres Isletas" },
    { value: 436, codProv: 5, label: "Villa Ángela" },
    { value: 437, codProv: 5, label: "Villa Berthet" },
    { value: 438, codProv: 5, label: "Villa R. Bermejito" },
    { value: 439, codProv: 6, label: "Aldea Apeleg" },
    { value: 440, codProv: 6, label: "Aldea Beleiro" },
    { value: 441, codProv: 6, label: "Aldea Epulef" },
    { value: 442, codProv: 6, label: "Alto Río Sengerr" },
    { value: 443, codProv: 6, label: "Buen Pasto" },
    { value: 444, codProv: 6, label: "Camarones" },
    { value: 445, codProv: 6, label: "Carrenleufú" },
    { value: 446, codProv: 6, label: "Cholila" },
    { value: 447, codProv: 6, label: "Co. Centinela" },
    { value: 448, codProv: 6, label: "Colan Conhué" },
    { value: 449, codProv: 6, label: "Comodoro Rivadavia" },
    { value: 450, codProv: 6, label: "Corcovado" },
    { value: 451, codProv: 6, label: "Cushamen" },
    { value: 452, codProv: 6, label: "Dique F. Ameghino" },
    { value: 453, codProv: 6, label: "Dolavón" },
    { value: 454, codProv: 6, label: "Dr. R. Rojas" },
    { value: 455, codProv: 6, label: "El Hoyo" },
    { value: 456, codProv: 6, label: "El Maitén" },
    { value: 457, codProv: 6, label: "Epuyén" },
    { value: 458, codProv: 6, label: "Esquel" },
    { value: 459, codProv: 6, label: "Facundo" },
    { value: 460, codProv: 6, label: "Gaimán" },
    { value: 461, codProv: 6, label: "Gan Gan" },
    { value: 462, codProv: 6, label: "Gastre" },
    { value: 463, codProv: 6, label: "Gdor. Costa" },
    { value: 464, codProv: 6, label: "Gualjaina" },
    { value: 465, codProv: 6, label: "J. de San Martín" },
    { value: 466, codProv: 6, label: "Lago Blanco" },
    { value: 467, codProv: 6, label: "Lago Puelo" },
    { value: 468, codProv: 6, label: "Lagunita Salada" },
    { value: 469, codProv: 6, label: "Las Plumas" },
    { value: 470, codProv: 6, label: "Los Altares" },
    { value: 471, codProv: 6, label: "Paso de los Indios" },
    { value: 472, codProv: 6, label: "Paso del Sapo" },
    { value: 473, codProv: 6, label: "Pto. Madryn" },
    { value: 474, codProv: 6, label: "Pto. Pirámvaluees" },
    { value: 475, codProv: 6, label: "Rada Tilly" },
    { value: 476, codProv: 6, label: "Rawson" },
    { value: 477, codProv: 6, label: "Río Mayo" },
    { value: 478, codProv: 6, label: "Río Pico" },
    { value: 479, codProv: 6, label: "Sarmiento" },
    { value: 480, codProv: 6, label: "Tecka" },
    { value: 481, codProv: 6, label: "Telsen" },
    { value: 482, codProv: 6, label: "Trelew" },
    { value: 483, codProv: 6, label: "Trevelin" },
    { value: 484, codProv: 6, label: "Veintiocho de Julio" },
    { value: 485, codProv: 7, label: "Achiras" },
    { value: 486, codProv: 7, label: "Adelia Maria" },
    { value: 487, codProv: 7, label: "Agua de Oro" },
    { value: 488, codProv: 7, label: "Alcira Gigena" },
    { value: 489, codProv: 7, label: "Aldea Santa Maria" },
    { value: 490, codProv: 7, label: "Alejandro Roca" },
    { value: 491, codProv: 7, label: "Alejo Ledesma" },
    { value: 492, codProv: 7, label: "Alicia" },
    { value: 493, codProv: 7, label: "Almafuerte" },
    { value: 494, codProv: 7, label: "Alpa Corral" },
    { value: 495, codProv: 7, label: "Alta Gracia" },
    { value: 496, codProv: 7, label: "Alto Alegre" },
    { value: 497, codProv: 7, label: "Alto de Los Quebrachos" },
    { value: 498, codProv: 7, label: "Altos de Chipion" },
    { value: 499, codProv: 7, label: "Amboy" },
    { value: 500, codProv: 7, label: "Ambul" },
    { value: 501, codProv: 7, label: "Ana Zumaran" },
    { value: 502, codProv: 7, label: "Anisacate" },
    { value: 503, codProv: 7, label: "Arguello" },
    { value: 504, codProv: 7, label: "Arias" },
    { value: 505, codProv: 7, label: "Arroyito" },
    { value: 506, codProv: 7, label: "Arroyo Algodon" },
    { value: 507, codProv: 7, label: "Arroyo Cabral" },
    { value: 508, codProv: 7, label: "Arroyo Los Patos" },
    { value: 509, codProv: 7, label: "Assunta" },
    { value: 510, codProv: 7, label: "Atahona" },
    { value: 511, codProv: 7, label: "Ausonia" },
    { value: 512, codProv: 7, label: "Avellaneda" },
    { value: 513, codProv: 7, label: "Ballesteros" },
    { value: 514, codProv: 7, label: "Ballesteros Sud" },
    { value: 515, codProv: 7, label: "Balnearia" },
    { value: 516, codProv: 7, label: "Bañado de Soto" },
    { value: 517, codProv: 7, label: "Bell Ville" },
    { value: 518, codProv: 7, label: "Bengolea" },
    { value: 519, codProv: 7, label: "Benjamin Gould" },
    { value: 520, codProv: 7, label: "Berrotaran" },
    { value: 521, codProv: 7, label: "Bialet Masse" },
    { value: 522, codProv: 7, label: "Bouwer" },
    { value: 523, codProv: 7, label: "Brinkmann" },
    { value: 524, codProv: 7, label: "Buchardo" },
    { value: 525, codProv: 7, label: "Bulnes" },
    { value: 526, codProv: 7, label: "Cabalango" },
    { value: 527, codProv: 7, label: "Calamuchita" },
    { value: 528, codProv: 7, label: "Calchin" },
    { value: 529, codProv: 7, label: "Calchin Oeste" },
    { value: 530, codProv: 7, label: "Calmayo" },
    { value: 531, codProv: 7, label: "Camilo Aldao" },
    { value: 532, codProv: 7, label: "Caminiaga" },
    { value: 533, codProv: 7, label: "Cañada de Luque" },
    { value: 534, codProv: 7, label: "Cañada de Machado" },
    { value: 535, codProv: 7, label: "Cañada de Rio Pinto" },
    { value: 536, codProv: 7, label: "Cañada del Sauce" },
    { value: 537, codProv: 7, label: "Canals" },
    { value: 538, codProv: 7, label: "Candelaria Sud" },
    { value: 539, codProv: 7, label: "Capilla de Remedios" },
    { value: 540, codProv: 7, label: "Capilla de Siton" },
    { value: 541, codProv: 7, label: "Capilla del Carmen" },
    { value: 542, codProv: 7, label: "Capilla del Monte" },
    { value: 543, codProv: 7, label: "Capital" },
    { value: 544, codProv: 7, label: "Capitan Gral B. O´Higgins" },
    { value: 545, codProv: 7, label: "Carnerillo" },
    { value: 546, codProv: 7, label: "Carrilobo" },
    { value: 547, codProv: 7, label: "Casa Grande" },
    { value: 548, codProv: 7, label: "Cavanagh" },
    { value: 549, codProv: 7, label: "Cerro Colorado" },
    { value: 550, codProv: 7, label: "Chaján" },
    { value: 551, codProv: 7, label: "Chalacea" },
    { value: 552, codProv: 7, label: "Chañar Viejo" },
    { value: 553, codProv: 7, label: "Chancaní" },
    { value: 554, codProv: 7, label: "Charbonier" },
    { value: 555, codProv: 7, label: "Charras" },
    { value: 556, codProv: 7, label: "Chazón" },
    { value: 557, codProv: 7, label: "Chilibroste" },
    { value: 558, codProv: 7, label: "Chucul" },
    { value: 559, codProv: 7, label: "Chuña" },
    { value: 560, codProv: 7, label: "Chuña Huasi" },
    { value: 561, codProv: 7, label: "Churqui Cañada" },
    { value: 562, codProv: 7, label: "Cienaga Del Coro" },
    { value: 563, codProv: 7, label: "Cintra" },
    { value: 564, codProv: 7, label: "Col. Almada" },
    { value: 565, codProv: 7, label: "Col. Anita" },
    { value: 566, codProv: 7, label: "Col. Barge" },
    { value: 567, codProv: 7, label: "Col. Bismark" },
    { value: 568, codProv: 7, label: "Col. Bremen" },
    { value: 569, codProv: 7, label: "Col. Caroya" },
    { value: 570, codProv: 7, label: "Col. Italiana" },
    { value: 571, codProv: 7, label: "Col. Iturraspe" },
    { value: 572, codProv: 7, label: "Col. Las Cuatro Esquinas" },
    { value: 573, codProv: 7, label: "Col. Las Pichanas" },
    { value: 574, codProv: 7, label: "Col. Marina" },
    { value: 575, codProv: 7, label: "Col. Prospervaluead" },
    { value: 576, codProv: 7, label: "Col. San Bartolome" },
    { value: 577, codProv: 7, label: "Col. San Pedro" },
    { value: 578, codProv: 7, label: "Col. Tirolesa" },
    { value: 579, codProv: 7, label: "Col. Vicente Aguero" },
    { value: 580, codProv: 7, label: "Col. Vvalueela" },
    { value: 581, codProv: 7, label: "Col. Vignaud" },
    { value: 582, codProv: 7, label: "Col. Waltelina" },
    { value: 583, codProv: 7, label: "Colazo" },
    { value: 584, codProv: 7, label: "Comechingones" },
    { value: 585, codProv: 7, label: "Conlara" },
    { value: 586, codProv: 7, label: "Copacabana" },
    { value: 587, codProv: 7, label: "7" },
    { value: 588, codProv: 7, label: "Coronel Baigorria" },
    { value: 589, codProv: 7, label: "Coronel Moldes" },
    { value: 590, codProv: 7, label: "Corral de Bustos" },
    { value: 591, codProv: 7, label: "Corralito" },
    { value: 592, codProv: 7, label: "Cosquín" },
    { value: 593, codProv: 7, label: "Costa Sacate" },
    { value: 594, codProv: 7, label: "Cruz Alta" },
    { value: 595, codProv: 7, label: "Cruz de Caña" },
    { value: 596, codProv: 7, label: "Cruz del Eje" },
    { value: 597, codProv: 7, label: "Cuesta Blanca" },
    { value: 598, codProv: 7, label: "Dean Funes" },
    { value: 599, codProv: 7, label: "Del Campillo" },
    { value: 600, codProv: 7, label: "Despeñaderos" },
    { value: 601, codProv: 7, label: "Devoto" },
    { value: 602, codProv: 7, label: "Diego de Rojas" },
    { value: 603, codProv: 7, label: "Dique Chico" },
    { value: 604, codProv: 7, label: "El Arañado" },
    { value: 605, codProv: 7, label: "El Brete" },
    { value: 606, codProv: 7, label: "El Chacho" },
    { value: 607, codProv: 7, label: "El Crispín" },
    { value: 608, codProv: 7, label: "El Fortín" },
    { value: 609, codProv: 7, label: "El Manzano" },
    { value: 610, codProv: 7, label: "El Rastreador" },
    { value: 611, codProv: 7, label: "El Rodeo" },
    { value: 612, codProv: 7, label: "El Tío" },
    { value: 613, codProv: 7, label: "Elena" },
    { value: 614, codProv: 7, label: "Embalse" },
    { value: 615, codProv: 7, label: "Esquina" },
    { value: 616, codProv: 7, label: "Estación Gral. Paz" },
    { value: 617, codProv: 7, label: "Estación Juárez Celman" },
    { value: 618, codProv: 7, label: "Estancia de Guadalupe" },
    { value: 619, codProv: 7, label: "Estancia Vieja" },
    { value: 620, codProv: 7, label: "Etruria" },
    { value: 621, codProv: 7, label: "Eufrasio Loza" },
    { value: 622, codProv: 7, label: "Falda del Carmen" },
    { value: 623, codProv: 7, label: "Freyre" },
    { value: 624, codProv: 7, label: "Gral. Baldissera" },
    { value: 625, codProv: 7, label: "Gral. Cabrera" },
    { value: 626, codProv: 7, label: "Gral. Deheza" },
    { value: 627, codProv: 7, label: "Gral. Fotheringham" },
    { value: 628, codProv: 7, label: "Gral. Levalle" },
    { value: 629, codProv: 7, label: "Gral. Roca" },
    { value: 630, codProv: 7, label: "Guanaco Muerto" },
    { value: 631, codProv: 7, label: "Guasapampa" },
    { value: 632, codProv: 7, label: "Guatimozin" },
    { value: 633, codProv: 7, label: "Gutenberg" },
    { value: 634, codProv: 7, label: "Hernando" },
    { value: 635, codProv: 7, label: "Huanchillas" },
    { value: 636, codProv: 7, label: "Huerta Grande" },
    { value: 637, codProv: 7, label: "Huinca Renanco" },
    { value: 638, codProv: 7, label: "valueiazabal" },
    { value: 639, codProv: 7, label: "Impira" },
    { value: 640, codProv: 7, label: "Inriville" },
    { value: 641, codProv: 7, label: "Isla Verde" },
    { value: 642, codProv: 7, label: "Italó" },
    { value: 643, codProv: 7, label: "James Craik" },
    { value: 644, codProv: 7, label: "Jesús María" },
    { value: 645, codProv: 7, label: "Jovita" },
    { value: 646, codProv: 7, label: "Justiniano Posse" },
    { value: 647, codProv: 7, label: "Km 658" },
    { value: 648, codProv: 7, label: "L. V. Mansilla" },
    { value: 649, codProv: 7, label: "La Batea" },
    { value: 650, codProv: 7, label: "La Calera" },
    { value: 651, codProv: 7, label: "La Carlota" },
    { value: 652, codProv: 7, label: "La Carolina" },
    { value: 653, codProv: 7, label: "La Cautiva" },
    { value: 654, codProv: 7, label: "La Cesira" },
    { value: 655, codProv: 7, label: "La Cruz" },
    { value: 656, codProv: 7, label: "La Cumbre" },
    { value: 657, codProv: 7, label: "La Cumbrecita" },
    { value: 658, codProv: 7, label: "La Falda" },
    { value: 659, codProv: 7, label: "La Francia" },
    { value: 660, codProv: 7, label: "La Granja" },
    { value: 661, codProv: 7, label: "La Higuera" },
    { value: 662, codProv: 7, label: "La Laguna" },
    { value: 663, codProv: 7, label: "La Paisanita" },
    { value: 664, codProv: 7, label: "La Palestina" },
    { value: 665, codProv: 7, label: "12" },
    { value: 666, codProv: 7, label: "La Paquita" },
    { value: 667, codProv: 7, label: "La Para" },
    { value: 668, codProv: 7, label: "La Paz" },
    { value: 669, codProv: 7, label: "La Playa" },
    { value: 670, codProv: 7, label: "La Playosa" },
    { value: 671, codProv: 7, label: "La Población" },
    { value: 672, codProv: 7, label: "La Posta" },
    { value: 673, codProv: 7, label: "La Puerta" },
    { value: 674, codProv: 7, label: "La Quinta" },
    { value: 675, codProv: 7, label: "La Rancherita" },
    { value: 676, codProv: 7, label: "La Rinconada" },
    { value: 677, codProv: 7, label: "La Serranita" },
    { value: 678, codProv: 7, label: "La Tordilla" },
    { value: 679, codProv: 7, label: "Laborde" },
    { value: 680, codProv: 7, label: "Laboulaye" },
    { value: 681, codProv: 7, label: "Laguna Larga" },
    { value: 682, codProv: 7, label: "Las Acequias" },
    { value: 683, codProv: 7, label: "Las Albahacas" },
    { value: 684, codProv: 7, label: "Las Arrias" },
    { value: 685, codProv: 7, label: "Las Bajadas" },
    { value: 686, codProv: 7, label: "Las Caleras" },
    { value: 687, codProv: 7, label: "Las Calles" },
    { value: 688, codProv: 7, label: "Las Cañadas" },
    { value: 689, codProv: 7, label: "Las Gramillas" },
    { value: 690, codProv: 7, label: "Las Higueras" },
    { value: 691, codProv: 7, label: "Las Isletillas" },
    { value: 692, codProv: 7, label: "Las Junturas" },
    { value: 693, codProv: 7, label: "Las Palmas" },
    { value: 694, codProv: 7, label: "Las Peñas" },
    { value: 695, codProv: 7, label: "Las Peñas Sud" },
    { value: 696, codProv: 7, label: "Las Perdices" },
    { value: 697, codProv: 7, label: "Las Playas" },
    { value: 698, codProv: 7, label: "Las Rabonas" },
    { value: 699, codProv: 7, label: "Las Saladas" },
    { value: 700, codProv: 7, label: "Las Tapias" },
    { value: 701, codProv: 7, label: "Las Varas" },
    { value: 702, codProv: 7, label: "Las Varillas" },
    { value: 703, codProv: 7, label: "Las Vertientes" },
    { value: 704, codProv: 7, label: "Leguizamón" },
    { value: 705, codProv: 7, label: "Leones" },
    { value: 706, codProv: 7, label: "Los Cedros" },
    { value: 707, codProv: 7, label: "Los Cerrillos" },
    { value: 708, codProv: 7, label: "Los Chañaritos (C.E)" },
    { value: 709, codProv: 7, label: "Los Chanaritos (R.S)" },
    { value: 710, codProv: 7, label: "Los Cisnes" },
    { value: 711, codProv: 7, label: "Los Cocos" },
    { value: 712, codProv: 7, label: "Los Cóndores" },
    { value: 713, codProv: 7, label: "Los Hornillos" },
    { value: 714, codProv: 7, label: "Los Hoyos" },
    { value: 715, codProv: 7, label: "Los Mistoles" },
    { value: 716, codProv: 7, label: "Los Molinos" },
    { value: 717, codProv: 7, label: "Los Pozos" },
    { value: 718, codProv: 7, label: "Los Reartes" },
    { value: 719, codProv: 7, label: "Los Surgentes" },
    { value: 720, codProv: 7, label: "Los Talares" },
    { value: 721, codProv: 7, label: "Los Zorros" },
    { value: 722, codProv: 7, label: "Lozada" },
    { value: 723, codProv: 7, label: "Luca" },
    { value: 724, codProv: 7, label: "Luque" },
    { value: 725, codProv: 7, label: "Luyaba" },
    { value: 726, codProv: 7, label: "Malagueño" },
    { value: 727, codProv: 7, label: "Malena" },
    { value: 728, codProv: 7, label: "Malvinas Argentinas" },
    { value: 729, codProv: 7, label: "Manfredi" },
    { value: 730, codProv: 7, label: "Maquinista Gallini" },
    { value: 731, codProv: 7, label: "Marcos Juárez" },
    { value: 732, codProv: 7, label: "Marull" },
    { value: 733, codProv: 7, label: "Matorrales" },
    { value: 734, codProv: 7, label: "Mattaldi" },
    { value: 735, codProv: 7, label: "Mayu Sumaj" },
    { value: 736, codProv: 7, label: "Media Naranja" },
    { value: 737, codProv: 7, label: "Melo" },
    { value: 738, codProv: 7, label: "Mendiolaza" },
    { value: 739, codProv: 7, label: "Mi Granja" },
    { value: 740, codProv: 7, label: "Mina Clavero" },
    { value: 741, codProv: 7, label: "Miramar" },
    { value: 742, codProv: 7, label: "Morrison" },
    { value: 743, codProv: 7, label: "Morteros" },
    { value: 744, codProv: 7, label: "Mte. Buey" },
    { value: 745, codProv: 7, label: "Mte. Cristo" },
    { value: 746, codProv: 7, label: "Mte. De Los Gauchos" },
    { value: 747, codProv: 7, label: "Mte. Leña" },
    { value: 748, codProv: 7, label: "Mte. Maíz" },
    { value: 749, codProv: 7, label: "Mte. Ralo" },
    { value: 750, codProv: 7, label: "Nicolás Bruzone" },
    { value: 751, codProv: 7, label: "Noetinger" },
    { value: 752, codProv: 7, label: "Nono" },
    { value: 753, codProv: 7, label: "Nueva 7" },
    { value: 754, codProv: 7, label: "Obispo Trejo" },
    { value: 755, codProv: 7, label: "Olaeta" },
    { value: 756, codProv: 7, label: "Oliva" },
    { value: 757, codProv: 7, label: "Olivares San Nicolás" },
    { value: 758, codProv: 7, label: "Onagolty" },
    { value: 759, codProv: 7, label: "Oncativo" },
    { value: 760, codProv: 7, label: "Ordoñez" },
    { value: 761, codProv: 7, label: "Pacheco De Melo" },
    { value: 762, codProv: 7, label: "Pampayasta N." },
    { value: 763, codProv: 7, label: "Pampayasta S." },
    { value: 764, codProv: 7, label: "Panaholma" },
    { value: 765, codProv: 7, label: "Pascanas" },
    { value: 766, codProv: 7, label: "Pasco" },
    { value: 767, codProv: 7, label: "Paso del Durazno" },
    { value: 768, codProv: 7, label: "Paso Viejo" },
    { value: 769, codProv: 7, label: "Pilar" },
    { value: 770, codProv: 7, label: "Pincén" },
    { value: 771, codProv: 7, label: "Piquillín" },
    { value: 772, codProv: 7, label: "Plaza de Mercedes" },
    { value: 773, codProv: 7, label: "Plaza Luxardo" },
    { value: 774, codProv: 7, label: "Porteña" },
    { value: 775, codProv: 7, label: "Potrero de Garay" },
    { value: 776, codProv: 7, label: "Pozo del Molle" },
    { value: 777, codProv: 7, label: "Pozo Nuevo" },
    { value: 778, codProv: 7, label: "Pueblo Italiano" },
    { value: 779, codProv: 7, label: "Puesto de Castro" },
    { value: 780, codProv: 7, label: "Punta del Agua" },
    { value: 781, codProv: 7, label: "Quebracho Herrado" },
    { value: 782, codProv: 7, label: "Quilino" },
    { value: 783, codProv: 7, label: "Rafael García" },
    { value: 784, codProv: 7, label: "Ranqueles" },
    { value: 785, codProv: 7, label: "Rayo Cortado" },
    { value: 786, codProv: 7, label: "Reducción" },
    { value: 787, codProv: 7, label: "Rincón" },
    { value: 788, codProv: 7, label: "Río Bamba" },
    { value: 789, codProv: 7, label: "Río Ceballos" },
    { value: 790, codProv: 7, label: "Río Cuarto" },
    { value: 791, codProv: 7, label: "Río de Los Sauces" },
    { value: 792, codProv: 7, label: "Río Primero" },
    { value: 793, codProv: 7, label: "Río Segundo" },
    { value: 794, codProv: 7, label: "Río Tercero" },
    { value: 795, codProv: 7, label: "Rosales" },
    { value: 796, codProv: 7, label: "Rosario del Saladillo" },
    { value: 797, codProv: 7, label: "Sacanta" },
    { value: 798, codProv: 7, label: "Sagrada Familia" },
    { value: 799, codProv: 7, label: "Saira" },
    { value: 800, codProv: 7, label: "Saladillo" },
    { value: 801, codProv: 7, label: "Saldán" },
    { value: 802, codProv: 7, label: "Salsacate" },
    { value: 803, codProv: 7, label: "Salsipuedes" },
    { value: 804, codProv: 7, label: "Sampacho" },
    { value: 805, codProv: 7, label: "San Agustín" },
    { value: 806, codProv: 7, label: "San Antonio de Arredondo" },
    { value: 807, codProv: 7, label: "San Antonio de Litín" },
    { value: 808, codProv: 7, label: "San Basilio" },
    { value: 809, codProv: 7, label: "San Carlos Minas" },
    { value: 810, codProv: 7, label: "San Clemente" },
    { value: 811, codProv: 7, label: "San Esteban" },
    { value: 812, codProv: 7, label: "San Francisco" },
    { value: 813, codProv: 7, label: "San Ignacio" },
    { value: 814, codProv: 7, label: "San Javier" },
    { value: 815, codProv: 7, label: "San Jerónimo" },
    { value: 816, codProv: 7, label: "San Joaquín" },
    { value: 817, codProv: 7, label: "San José de La Dormvaluea" },
    { value: 818, codProv: 7, label: "San José de Las Salinas" },
    { value: 819, codProv: 7, label: "San Lorenzo" },
    { value: 820, codProv: 7, label: "San Marcos Sierras" },
    { value: 821, codProv: 7, label: "San Marcos Sud" },
    { value: 822, codProv: 7, label: "San Pedro" },
    { value: 823, codProv: 7, label: "San Pedro N." },
    { value: 824, codProv: 7, label: "San Roque" },
    { value: 825, codProv: 7, label: "San Vicente" },
    { value: 826, codProv: 7, label: "Santa Catalina" },
    { value: 827, codProv: 7, label: "Santa Elena" },
    { value: 828, codProv: 7, label: "Santa Eufemia" },
    { value: 829, codProv: 7, label: "Santa Maria" },
    { value: 830, codProv: 7, label: "Sarmiento" },
    { value: 831, codProv: 7, label: "Saturnino M.Laspiur" },
    { value: 832, codProv: 7, label: "Sauce Arriba" },
    { value: 833, codProv: 7, label: "Sebastián Elcano" },
    { value: 834, codProv: 7, label: "Seeber" },
    { value: 835, codProv: 7, label: "Segunda Usina" },
    { value: 836, codProv: 7, label: "Serrano" },
    { value: 837, codProv: 7, label: "Serrezuela" },
    { value: 838, codProv: 7, label: "Sgo. Temple" },
    { value: 839, codProv: 7, label: "Silvio Pellico" },
    { value: 840, codProv: 7, label: "Simbolar" },
    { value: 841, codProv: 7, label: "Sinsacate" },
    { value: 842, codProv: 7, label: "Sta. Rosa de Calamuchita" },
    { value: 843, codProv: 7, label: "Sta. Rosa de Río Primero" },
    { value: 844, codProv: 7, label: "Suco" },
    { value: 845, codProv: 7, label: "Tala Cañada" },
    { value: 846, codProv: 7, label: "Tala Huasi" },
    { value: 847, codProv: 7, label: "Talaini" },
    { value: 848, codProv: 7, label: "Tancacha" },
    { value: 849, codProv: 7, label: "Tanti" },
    { value: 850, codProv: 7, label: "Ticino" },
    { value: 851, codProv: 7, label: "Tinoco" },
    { value: 852, codProv: 7, label: "Tío Pujio" },
    { value: 853, codProv: 7, label: "Toledo" },
    { value: 854, codProv: 7, label: "Toro Pujio" },
    { value: 855, codProv: 7, label: "Tosno" },
    { value: 856, codProv: 7, label: "Tosquita" },
    { value: 857, codProv: 7, label: "Tránsito" },
    { value: 858, codProv: 7, label: "Tuclame" },
    { value: 859, codProv: 7, label: "Tutti" },
    { value: 860, codProv: 7, label: "Ucacha" },
    { value: 861, codProv: 7, label: "Unquillo" },
    { value: 862, codProv: 7, label: "Valle de Anisacate" },
    { value: 863, codProv: 7, label: "Valle Hermoso" },
    { value: 864, codProv: 7, label: "Vélez Sarfield" },
    { value: 865, codProv: 7, label: "Viamonte" },
    { value: 866, codProv: 7, label: "Vicuña Mackenna" },
    { value: 867, codProv: 7, label: "Villa Allende" },
    { value: 868, codProv: 7, label: "Villa Amancay" },
    { value: 869, codProv: 7, label: "Villa Ascasubi" },
    { value: 870, codProv: 7, label: "Villa Candelaria N." },
    { value: 871, codProv: 7, label: "Villa Carlos Paz" },
    { value: 872, codProv: 7, label: "Villa Cerro Azul" },
    { value: 873, codProv: 7, label: "Villa Ciudad de América" },
    { value: 874, codProv: 7, label: "Villa Ciudad Pque Los Reartes" },
    { value: 875, codProv: 7, label: "Villa Concepción del Tío" },
    { value: 876, codProv: 7, label: "Villa Cura Brochero" },
    { value: 877, codProv: 7, label: "Villa de Las Rosas" },
    { value: 878, codProv: 7, label: "Villa de María" },
    { value: 879, codProv: 7, label: "Villa de Pocho" },
    { value: 880, codProv: 7, label: "Villa de Soto" },
    { value: 881, codProv: 7, label: "Villa del Dique" },
    { value: 882, codProv: 7, label: "Villa del Prado" },
    { value: 883, codProv: 7, label: "Villa del Rosario" },
    { value: 884, codProv: 7, label: "Villa del Totoral" },
    { value: 885, codProv: 7, label: "Villa Dolores" },
    { value: 886, codProv: 7, label: "Villa El Chancay" },
    { value: 887, codProv: 7, label: "Villa Elisa" },
    { value: 888, codProv: 7, label: "Villa Flor Serrana" },
    { value: 889, codProv: 7, label: "Villa Fontana" },
    { value: 890, codProv: 7, label: "Villa Giardino" },
    { value: 891, codProv: 7, label: "Villa Gral. Belgrano" },
    { value: 892, codProv: 7, label: "Villa Gutierrez" },
    { value: 893, codProv: 7, label: "Villa Huvalueobro" },
    { value: 894, codProv: 7, label: "Villa La Bolsa" },
    { value: 895, codProv: 7, label: "Villa Los Aromos" },
    { value: 896, codProv: 7, label: "Villa Los Patos" },
    { value: 897, codProv: 7, label: "Villa María" },
    { value: 898, codProv: 7, label: "Villa Nueva" },
    { value: 899, codProv: 7, label: "Villa Pque. Santa Ana" },
    { value: 900, codProv: 7, label: "Villa Pque. Siquiman" },
    { value: 901, codProv: 7, label: "Villa Quillinzo" },
    { value: 902, codProv: 7, label: "Villa Rossi" },
    { value: 903, codProv: 7, label: "Villa Rumipal" },
    { value: 904, codProv: 7, label: "Villa San Esteban" },
    { value: 905, codProv: 7, label: "Villa San Isvaluero" },
    { value: 906, codProv: 7, label: "Villa 21" },
    { value: 907, codProv: 7, label: "Villa Sarmiento (G.R)" },
    { value: 908, codProv: 7, label: "Villa Sarmiento (S.A)" },
    { value: 909, codProv: 7, label: "Villa Tulumba" },
    { value: 910, codProv: 7, label: "Villa Valeria" },
    { value: 911, codProv: 7, label: "Villa Yacanto" },
    { value: 912, codProv: 7, label: "Washington" },
    { value: 913, codProv: 7, label: "Wenceslao Escalante" },
    { value: 914, codProv: 7, label: "Ycho Cruz Sierras" },
    { value: 915, codProv: 8, label: "Alvear" },
    { value: 916, codProv: 8, label: "Bella Vista" },
    { value: 917, codProv: 8, label: "Berón de Astrada" },
    { value: 918, codProv: 8, label: "Bonpland" },
    { value: 919, codProv: 8, label: "Caá Cati" },
    { value: 920, codProv: 8, label: "Capital" },
    { value: 921, codProv: 8, label: "Chavarría" },
    { value: 922, codProv: 8, label: "Col. C. Pellegrini" },
    { value: 923, codProv: 8, label: "Col. Libertad" },
    { value: 924, codProv: 8, label: "Col. Liebig" },
    { value: 925, codProv: 8, label: "Col. Sta Rosa" },
    { value: 926, codProv: 8, label: "Concepción" },
    { value: 927, codProv: 8, label: "Cruz de Los Milagros" },
    { value: 928, codProv: 8, label: "Curuzú-Cuatiá" },
    { value: 929, codProv: 8, label: "Empedrado" },
    { value: 930, codProv: 8, label: "Esquina" },
    { value: 931, codProv: 8, label: "Estación Torrent" },
    { value: 932, codProv: 8, label: "Felipe Yofré" },
    { value: 933, codProv: 8, label: "Garruchos" },
    { value: 934, codProv: 8, label: "Gdor. Agrónomo" },
    { value: 935, codProv: 8, label: "Gdor. Martínez" },
    { value: 936, codProv: 8, label: "Goya" },
    { value: 937, codProv: 8, label: "Guaviravi" },
    { value: 938, codProv: 8, label: "Herlitzka" },
    { value: 939, codProv: 8, label: "Ita-Ibate" },
    { value: 940, codProv: 8, label: "Itatí" },
    { value: 941, codProv: 8, label: "Ituzaingó" },
    { value: 942, codProv: 8, label: "José Rafael Gómez" },
    { value: 943, codProv: 8, label: "Juan Pujol" },
    { value: 944, codProv: 8, label: "La Cruz" },
    { value: 945, codProv: 8, label: "Lavalle" },
    { value: 946, codProv: 8, label: "Lomas de Vallejos" },
    { value: 947, codProv: 8, label: "Loreto" },
    { value: 948, codProv: 8, label: "Mariano I. Loza" },
    { value: 949, codProv: 8, label: "Mburucuyá" },
    { value: 950, codProv: 8, label: "Mercedes" },
    { value: 951, codProv: 8, label: "Mocoretá" },
    { value: 952, codProv: 8, label: "Mte. Caseros" },
    { value: 953, codProv: 8, label: "Nueve de Julio" },
    { value: 954, codProv: 8, label: "Palmar Grande" },
    { value: 955, codProv: 8, label: "Parada Pucheta" },
    { value: 956, codProv: 8, label: "Paso de La Patria" },
    { value: 957, codProv: 8, label: "Paso de Los Libres" },
    { value: 958, codProv: 8, label: "Pedro R. Fernandez" },
    { value: 959, codProv: 8, label: "Perugorría" },
    { value: 960, codProv: 8, label: "Pueblo Libertador" },
    { value: 961, codProv: 8, label: "Ramada Paso" },
    { value: 962, codProv: 8, label: "Riachuelo" },
    { value: 963, codProv: 8, label: "Saladas" },
    { value: 964, codProv: 8, label: "San Antonio" },
    { value: 965, codProv: 8, label: "San Carlos" },
    { value: 966, codProv: 8, label: "San Cosme" },
    { value: 967, codProv: 8, label: "San Lorenzo" },
    { value: 968, codProv: 8, label: "20 del Palmar" },
    { value: 969, codProv: 8, label: "San Miguel" },
    { value: 970, codProv: 8, label: "San Roque" },
    { value: 971, codProv: 8, label: "Santa Ana" },
    { value: 972, codProv: 8, label: "Santa Lucía" },
    { value: 973, codProv: 8, label: "Santo Tomé" },
    { value: 974, codProv: 8, label: "Sauce" },
    { value: 975, codProv: 8, label: "Tabay" },
    { value: 976, codProv: 8, label: "Tapebicuá" },
    { value: 977, codProv: 8, label: "Tatacua" },
    { value: 978, codProv: 8, label: "Virasoro" },
    { value: 979, codProv: 8, label: "Yapeyú" },
    { value: 980, codProv: 8, label: "Yataití Calle" },
    { value: 981, codProv: 9, label: "Alarcón" },
    { value: 982, codProv: 9, label: "Alcaraz" },
    { value: 983, codProv: 9, label: "Alcaraz N." },
    { value: 984, codProv: 9, label: "Alcaraz S." },
    { value: 985, codProv: 9, label: "Aldea Asunción" },
    { value: 986, codProv: 9, label: "Aldea Brasilera" },
    { value: 987, codProv: 9, label: "Aldea Elgenfeld" },
    { value: 988, codProv: 9, label: "Aldea Grapschental" },
    { value: 989, codProv: 9, label: "Aldea Ma. Luisa" },
    { value: 990, codProv: 9, label: "Aldea Protestante" },
    { value: 991, codProv: 9, label: "Aldea Salto" },
    { value: 992, codProv: 9, label: "Aldea San Antonio (G)" },
    { value: 993, codProv: 9, label: "Aldea San Antonio (P)" },
    { value: 994, codProv: 9, label: "Aldea 19" },
    { value: 995, codProv: 9, label: "Aldea San Miguel" },
    { value: 996, codProv: 9, label: "Aldea San Rafael" },
    { value: 997, codProv: 9, label: "Aldea Spatzenkutter" },
    { value: 998, codProv: 9, label: "Aldea Sta. María" },
    { value: 999, codProv: 9, label: "Aldea Sta. Rosa" },
    { value: 1000, codProv: 9, label: "Aldea Valle María" },
    { value: 1001, codProv: 9, label: "Altamirano Sur" },
    { value: 1002, codProv: 9, label: "Antelo" },
    { value: 1003, codProv: 9, label: "Antonio Tomás" },
    { value: 1004, codProv: 9, label: "Aranguren" },
    { value: 1005, codProv: 9, label: "Arroyo Barú" },
    { value: 1006, codProv: 9, label: "Arroyo Burgos" },
    { value: 1007, codProv: 9, label: "Arroyo Clé" },
    { value: 1008, codProv: 9, label: "Arroyo Corralito" },
    { value: 1009, codProv: 9, label: "Arroyo del Medio" },
    { value: 1010, codProv: 9, label: "Arroyo Maturrango" },
    { value: 1011, codProv: 9, label: "Arroyo Palo Seco" },
    { value: 1012, codProv: 9, label: "Banderas" },
    { value: 1013, codProv: 9, label: "Basavilbaso" },
    { value: 1014, codProv: 9, label: "Betbeder" },
    { value: 1015, codProv: 9, label: "Bovril" },
    { value: 1016, codProv: 9, label: "Caseros" },
    { value: 1017, codProv: 9, label: "Ceibas" },
    { value: 1018, codProv: 9, label: "Cerrito" },
    { value: 1019, codProv: 9, label: "Chajarí" },
    { value: 1020, codProv: 9, label: "Chilcas" },
    { value: 1021, codProv: 9, label: "Clodomiro Ledesma" },
    { value: 1022, codProv: 9, label: "Col. Alemana" },
    { value: 1023, codProv: 9, label: "Col. Avellaneda" },
    { value: 1024, codProv: 9, label: "Col. Avigdor" },
    { value: 1025, codProv: 9, label: "Col. Ayuí" },
    { value: 1026, codProv: 9, label: "Col. Baylina" },
    { value: 1027, codProv: 9, label: "Col. Carrasco" },
    { value: 1028, codProv: 9, label: "Col. Celina" },
    { value: 1029, codProv: 9, label: "Col. Cerrito" },
    { value: 1030, codProv: 9, label: "Col. Crespo" },
    { value: 1031, codProv: 9, label: "Col. Elia" },
    { value: 1032, codProv: 9, label: "Col. Ensayo" },
    { value: 1033, codProv: 9, label: "Col. Gral. Roca" },
    { value: 1034, codProv: 9, label: "Col. La Argentina" },
    { value: 1035, codProv: 9, label: "Col. Merou" },
    { value: 1036, codProv: 9, label: "Col. Oficial Nª3" },
    { value: 1037, codProv: 9, label: "Col. Oficial Nº13" },
    { value: 1038, codProv: 9, label: "Col. Oficial Nº14" },
    { value: 1039, codProv: 9, label: "Col. Oficial Nº5" },
    { value: 1040, codProv: 9, label: "Col. Reffino" },
    { value: 1041, codProv: 9, label: "Col. Tunas" },
    { value: 1042, codProv: 9, label: "Col. Viraró" },
    { value: 1043, codProv: 9, label: "Colón" },
    { value: 1044, codProv: 9, label: "Concepción del Uruguay" },
    { value: 1045, codProv: 9, label: "Concordia" },
    { value: 1046, codProv: 9, label: "Conscripto Bernardi" },
    { value: 1047, codProv: 9, label: "Costa Grande" },
    { value: 1048, codProv: 9, label: "Costa San Antonio" },
    { value: 1049, codProv: 9, label: "Costa Uruguay N." },
    { value: 1050, codProv: 9, label: "Costa Uruguay S." },
    { value: 1051, codProv: 9, label: "Crespo" },
    { value: 1052, codProv: 9, label: "Crucecitas 3ª" },
    { value: 1053, codProv: 9, label: "Crucecitas 7ª" },
    { value: 1054, codProv: 9, label: "Crucecitas 8ª" },
    { value: 1055, codProv: 9, label: "Cuchilla Redonda" },
    { value: 1056, codProv: 9, label: "Curtiembre" },
    { value: 1057, codProv: 9, label: "Diamante" },
    { value: 1058, codProv: 9, label: "Distrito 6º" },
    { value: 1059, codProv: 9, label: "Distrito Chañar" },
    { value: 1060, codProv: 9, label: "Distrito Chiqueros" },
    { value: 1061, codProv: 9, label: "Distrito Cuarto" },
    { value: 1062, codProv: 9, label: "Distrito Diego López" },
    { value: 1063, codProv: 9, label: "Distrito Pajonal" },
    { value: 1064, codProv: 9, label: "Distrito Sauce" },
    { value: 1065, codProv: 9, label: "Distrito Tala" },
    { value: 1066, codProv: 9, label: "Distrito Talitas" },
    { value: 1067, codProv: 9, label: "Don Cristóbal 1ª Sección" },
    { value: 1068, codProv: 9, label: "Don Cristóbal 2ª Sección" },
    { value: 1069, codProv: 9, label: "Durazno" },
    { value: 1070, codProv: 9, label: "El Cimarrón" },
    { value: 1071, codProv: 9, label: "El Gramillal" },
    { value: 1072, codProv: 9, label: "El Palenque" },
    { value: 1073, codProv: 9, label: "El Pingo" },
    { value: 1074, codProv: 9, label: "El Quebracho" },
    { value: 1075, codProv: 9, label: "El Redomón" },
    { value: 1076, codProv: 9, label: "El Solar" },
    { value: 1077, codProv: 9, label: "Enrique Carbo" },
    { value: 1078, codProv: 9, label: "9" },
    { value: 1079, codProv: 9, label: "Espinillo N." },
    { value: 1080, codProv: 9, label: "Estación Campos" },
    { value: 1081, codProv: 9, label: "Estación Escriña" },
    { value: 1082, codProv: 9, label: "Estación Lazo" },
    { value: 1083, codProv: 9, label: "Estación Raíces" },
    { value: 1084, codProv: 9, label: "Estación Yerúa" },
    { value: 1085, codProv: 9, label: "Estancia Grande" },
    { value: 1086, codProv: 9, label: "Estancia Líbaros" },
    { value: 1087, codProv: 9, label: "Estancia Racedo" },
    { value: 1088, codProv: 9, label: "Estancia Solá" },
    { value: 1089, codProv: 9, label: "Estancia Yuquerí" },
    { value: 1090, codProv: 9, label: "Estaquitas" },
    { value: 1091, codProv: 9, label: "Faustino M. Parera" },
    { value: 1092, codProv: 9, label: "Febre" },
    { value: 1093, codProv: 9, label: "Federación" },
    { value: 1094, codProv: 9, label: "Federal" },
    { value: 1095, codProv: 9, label: "Gdor. Echagüe" },
    { value: 1096, codProv: 9, label: "Gdor. Mansilla" },
    { value: 1097, codProv: 9, label: "Gilbert" },
    { value: 1098, codProv: 9, label: "González Calderón" },
    { value: 1099, codProv: 9, label: "Gral. Almada" },
    { value: 1100, codProv: 9, label: "Gral. Alvear" },
    { value: 1101, codProv: 9, label: "Gral. Campos" },
    { value: 1102, codProv: 9, label: "Gral. Galarza" },
    { value: 1103, codProv: 9, label: "Gral. Ramírez" },
    { value: 1104, codProv: 9, label: "Gualeguay" },
    { value: 1105, codProv: 9, label: "Gualeguaychú" },
    { value: 1106, codProv: 9, label: "Gualeguaycito" },
    { value: 1107, codProv: 9, label: "Guardamonte" },
    { value: 1108, codProv: 9, label: "Hambis" },
    { value: 1109, codProv: 9, label: "Hasenkamp" },
    { value: 1110, codProv: 9, label: "Hernandarias" },
    { value: 1111, codProv: 9, label: "Hernández" },
    { value: 1112, codProv: 9, label: "Herrera" },
    { value: 1113, codProv: 9, label: "Hinojal" },
    { value: 1114, codProv: 9, label: "Hocker" },
    { value: 1115, codProv: 9, label: "Ing. Sajaroff" },
    { value: 1116, codProv: 9, label: "Irazusta" },
    { value: 1117, codProv: 9, label: "Isletas" },
    { value: 1118, codProv: 9, label: "J.J De Urquiza" },
    { value: 1119, codProv: 9, label: "Jubileo" },
    { value: 1120, codProv: 9, label: "La Clarita" },
    { value: 1121, codProv: 9, label: "La Criolla" },
    { value: 1122, codProv: 9, label: "La Esmeralda" },
    { value: 1123, codProv: 9, label: "La Florvaluea" },
    { value: 1124, codProv: 9, label: "La Fraternvaluead" },
    { value: 1125, codProv: 9, label: "La Hierra" },
    { value: 1126, codProv: 9, label: "La Ollita" },
    { value: 1127, codProv: 9, label: "La Paz" },
    { value: 1128, codProv: 9, label: "La Picada" },
    { value: 1129, codProv: 9, label: "La Provvalueencia" },
    { value: 1130, codProv: 9, label: "La Verbena" },
    { value: 1131, codProv: 9, label: "Laguna Benítez" },
    { value: 1132, codProv: 9, label: "Larroque" },
    { value: 1133, codProv: 9, label: "Las Cuevas" },
    { value: 1134, codProv: 9, label: "Las Garzas" },
    { value: 1135, codProv: 9, label: "Las Guachas" },
    { value: 1136, codProv: 9, label: "Las Mercedes" },
    { value: 1137, codProv: 9, label: "Las Moscas" },
    { value: 1138, codProv: 9, label: "Las Mulitas" },
    { value: 1139, codProv: 9, label: "Las Toscas" },
    { value: 1140, codProv: 9, label: "Laurencena" },
    { value: 1141, codProv: 9, label: "Libertador San Martín" },
    { value: 1142, codProv: 9, label: "Loma Limpia" },
    { value: 1143, codProv: 9, label: "Los Ceibos" },
    { value: 1144, codProv: 9, label: "Los Charruas" },
    { value: 1145, codProv: 9, label: "Los Conquistadores" },
    { value: 1146, codProv: 9, label: "Lucas González" },
    { value: 1147, codProv: 9, label: "Lucas N." },
    { value: 1148, codProv: 9, label: "Lucas S. 1ª" },
    { value: 1149, codProv: 9, label: "Lucas S. 2ª" },
    { value: 1150, codProv: 9, label: "Maciá" },
    { value: 1151, codProv: 9, label: "María Grande" },
    { value: 1152, codProv: 9, label: "María Grande 2ª" },
    { value: 1153, codProv: 9, label: "Médanos" },
    { value: 1154, codProv: 9, label: "Mojones N." },
    { value: 1155, codProv: 9, label: "Mojones S." },
    { value: 1156, codProv: 9, label: "Molino Doll" },
    { value: 1157, codProv: 9, label: "Monte Redondo" },
    { value: 1158, codProv: 9, label: "Montoya" },
    { value: 1159, codProv: 9, label: "Mulas Grandes" },
    { value: 1160, codProv: 9, label: "Ñancay" },
    { value: 1161, codProv: 9, label: "Nogoyá" },
    { value: 1162, codProv: 9, label: "Nueva Escocia" },
    { value: 1163, codProv: 9, label: "Nueva Vizcaya" },
    { value: 1164, codProv: 9, label: "Ombú" },
    { value: 1165, codProv: 9, label: "Oro Verde" },
    { value: 1166, codProv: 9, label: "Paraná" },
    { value: 1167, codProv: 9, label: "Pasaje Guayaquil" },
    { value: 1168, codProv: 9, label: "Pasaje Las Tunas" },
    { value: 1169, codProv: 9, label: "Paso de La Arena" },
    { value: 1170, codProv: 9, label: "Paso de La Laguna" },
    { value: 1171, codProv: 9, label: "Paso de Las Piedras" },
    { value: 1172, codProv: 9, label: "Paso Duarte" },
    { value: 1173, codProv: 9, label: "Pastor Britos" },
    { value: 1174, codProv: 9, label: "Pedernal" },
    { value: 1175, codProv: 9, label: "Perdices" },
    { value: 1176, codProv: 9, label: "Picada Berón" },
    { value: 1177, codProv: 9, label: "Piedras Blancas" },
    { value: 1178, codProv: 9, label: "Primer Distrito Cuchilla" },
    { value: 1179, codProv: 9, label: "Primero de Mayo" },
    { value: 1180, codProv: 9, label: "Pronunciamiento" },
    { value: 1181, codProv: 9, label: "Pto. Algarrobo" },
    { value: 1182, codProv: 9, label: "Pto. Ibicuy" },
    { value: 1183, codProv: 9, label: "Pueblo Brugo" },
    { value: 1184, codProv: 9, label: "Pueblo Cazes" },
    { value: 1185, codProv: 9, label: "Pueblo Gral. Belgrano" },
    { value: 1186, codProv: 9, label: "Pueblo Liebig" },
    { value: 1187, codProv: 9, label: "Puerto Yeruá" },
    { value: 1188, codProv: 9, label: "Punta del Monte" },
    { value: 1189, codProv: 9, label: "Quebracho" },
    { value: 1190, codProv: 9, label: "Quinto Distrito" },
    { value: 1191, codProv: 9, label: "Raices Oeste" },
    { value: 1192, codProv: 9, label: "Rincón de Nogoyá" },
    { value: 1193, codProv: 9, label: "Rincón del Cinto" },
    { value: 1194, codProv: 9, label: "Rincón del Doll" },
    { value: 1195, codProv: 9, label: "Rincón del Gato" },
    { value: 1196, codProv: 9, label: "Rocamora" },
    { value: 1197, codProv: 9, label: "Rosario del Tala" },
    { value: 1198, codProv: 9, label: "San Benito" },
    { value: 1199, codProv: 9, label: "San Cipriano" },
    { value: 1200, codProv: 9, label: "San Ernesto" },
    { value: 1201, codProv: 9, label: "San Gustavo" },
    { value: 1202, codProv: 9, label: "San Jaime" },
    { value: 1203, codProv: 9, label: "San José" },
    { value: 1204, codProv: 9, label: "San José de Feliciano" },
    { value: 1205, codProv: 9, label: "San Justo" },
    { value: 1206, codProv: 9, label: "San Marcial" },
    { value: 1207, codProv: 9, label: "San Pedro" },
    { value: 1208, codProv: 9, label: "San Ramírez" },
    { value: 1209, codProv: 9, label: "San Ramón" },
    { value: 1210, codProv: 9, label: "San Roque" },
    { value: 1211, codProv: 9, label: "San Salvador" },
    { value: 1212, codProv: 9, label: "San Víctor" },
    { value: 1213, codProv: 9, label: "Santa Ana" },
    { value: 1214, codProv: 9, label: "Santa Anita" },
    { value: 1215, codProv: 9, label: "Santa Elena" },
    { value: 1216, codProv: 9, label: "Santa Lucía" },
    { value: 1217, codProv: 9, label: "Santa Luisa" },
    { value: 1218, codProv: 9, label: "Sauce de Luna" },
    { value: 1219, codProv: 9, label: "Sauce Montrull" },
    { value: 1220, codProv: 9, label: "Sauce Pinto" },
    { value: 1221, codProv: 9, label: "Sauce Sur" },
    { value: 1222, codProv: 9, label: "Seguí" },
    { value: 1223, codProv: 9, label: "Sir Leonard" },
    { value: 1224, codProv: 9, label: "Sosa" },
    { value: 1225, codProv: 9, label: "Tabossi" },
    { value: 1226, codProv: 9, label: "Tezanos Pinto" },
    { value: 1227, codProv: 9, label: "Ubajay" },
    { value: 1228, codProv: 9, label: "Urdinarrain" },
    { value: 1229, codProv: 9, label: "Veinte de Septiembre" },
    { value: 1230, codProv: 9, label: "Viale" },
    { value: 1231, codProv: 9, label: "Victoria" },
    { value: 1232, codProv: 9, label: "Villa Clara" },
    { value: 1233, codProv: 9, label: "Villa del Rosario" },
    { value: 1234, codProv: 9, label: "Villa Domínguez" },
    { value: 1235, codProv: 9, label: "Villa Elisa" },
    { value: 1236, codProv: 9, label: "Villa Fontana" },
    { value: 1237, codProv: 9, label: "Villa Gdor. Etchevehere" },
    { value: 1238, codProv: 9, label: "Villa Mantero" },
    { value: 1239, codProv: 9, label: "Villa Paranacito" },
    { value: 1240, codProv: 9, label: "Villa Urquiza" },
    { value: 1241, codProv: 9, label: "Villaguay" },
    { value: 1242, codProv: 9, label: "Walter Moss" },
    { value: 1243, codProv: 9, label: "Yacaré" },
    { value: 1244, codProv: 9, label: "Yeso Oeste" },
    { value: 1245, codProv: 10, label: "Buena Vista" },
    { value: 1246, codProv: 10, label: "Clorinda" },
    { value: 1247, codProv: 10, label: "Col. Pastoril" },
    { value: 1248, codProv: 10, label: "Cte. Fontana" },
    { value: 1249, codProv: 10, label: "El Colorado" },
    { value: 1250, codProv: 10, label: "El Espinillo" },
    { value: 1251, codProv: 10, label: "Estanislao Del Campo" },
    { value: 1252, codProv: 10, label: "10" },
    { value: 1253, codProv: 10, label: "Fortín Lugones" },
    { value: 1254, codProv: 10, label: "Gral. Lucio V. Mansilla" },
    { value: 1255, codProv: 10, label: "Gral. Manuel Belgrano" },
    { value: 1256, codProv: 10, label: "Gral. Mosconi" },
    { value: 1257, codProv: 10, label: "Gran Guardia" },
    { value: 1258, codProv: 10, label: "Herradura" },
    { value: 1259, codProv: 10, label: "Ibarreta" },
    { value: 1260, codProv: 10, label: "Ing. Juárez" },
    { value: 1261, codProv: 10, label: "Laguna Blanca" },
    { value: 1262, codProv: 10, label: "Laguna Naick Neck" },
    { value: 1263, codProv: 10, label: "Laguna Yema" },
    { value: 1264, codProv: 10, label: "Las Lomitas" },
    { value: 1265, codProv: 10, label: "Los Chiriguanos" },
    { value: 1266, codProv: 10, label: "Mayor V. Villafañe" },
    { value: 1267, codProv: 10, label: "Misión San Fco." },
    { value: 1268, codProv: 10, label: "Palo Santo" },
    { value: 1269, codProv: 10, label: "Pirané" },
    { value: 1270, codProv: 10, label: "Pozo del Maza" },
    { value: 1271, codProv: 10, label: "Riacho He-He" },
    { value: 1272, codProv: 10, label: "San Hilario" },
    { value: 1273, codProv: 10, label: "San Martín II" },
    { value: 1274, codProv: 10, label: "Siete Palmas" },
    { value: 1275, codProv: 10, label: "Subteniente Perín" },
    { value: 1276, codProv: 10, label: "Tres Lagunas" },
    { value: 1277, codProv: 10, label: "Villa Dos Trece" },
    { value: 1278, codProv: 10, label: "Villa Escolar" },
    { value: 1279, codProv: 10, label: "Villa Gral. Güemes" },
    { value: 1280, codProv: 11, label: "Abdon Castro Tolay" },
    { value: 1281, codProv: 11, label: "Abra Pampa" },
    { value: 1282, codProv: 11, label: "Abralaite" },
    { value: 1283, codProv: 11, label: "Aguas Calientes" },
    { value: 1284, codProv: 11, label: "Arrayanal" },
    { value: 1285, codProv: 11, label: "Barrios" },
    { value: 1286, codProv: 11, label: "Caimancito" },
    { value: 1287, codProv: 11, label: "Calilegua" },
    { value: 1288, codProv: 11, label: "Cangrejillos" },
    { value: 1289, codProv: 11, label: "Caspala" },
    { value: 1290, codProv: 11, label: "Catuá" },
    { value: 1291, codProv: 11, label: "Cieneguillas" },
    { value: 1292, codProv: 11, label: "Coranzulli" },
    { value: 1293, codProv: 11, label: "Cusi-Cusi" },
    { value: 1294, codProv: 11, label: "El Aguilar" },
    { value: 1295, codProv: 11, label: "El Carmen" },
    { value: 1296, codProv: 11, label: "El Cóndor" },
    { value: 1297, codProv: 11, label: "El Fuerte" },
    { value: 1298, codProv: 11, label: "El Piquete" },
    { value: 1299, codProv: 11, label: "El Talar" },
    { value: 1300, codProv: 11, label: "Fraile Pintado" },
    { value: 1301, codProv: 11, label: "Hipólito Yrigoyen" },
    { value: 1302, codProv: 11, label: "Huacalera" },
    { value: 1303, codProv: 11, label: "Humahuaca" },
    { value: 1304, codProv: 11, label: "La Esperanza" },
    { value: 1305, codProv: 11, label: "La Mendieta" },
    { value: 1306, codProv: 11, label: "La Quiaca" },
    { value: 1307, codProv: 11, label: "Ledesma" },
    { value: 1308, codProv: 11, label: "Libertador Gral. San Martin" },
    { value: 1309, codProv: 11, label: "Maimara" },
    { value: 1310, codProv: 11, label: "Mina Pirquitas" },
    { value: 1311, codProv: 11, label: "Monterrico" },
    { value: 1312, codProv: 11, label: "Palma Sola" },
    { value: 1313, codProv: 11, label: "Palpalá" },
    { value: 1314, codProv: 11, label: "Pampa Blanca" },
    { value: 1315, codProv: 11, label: "Pampichuela" },
    { value: 1316, codProv: 11, label: "Perico" },
    { value: 1317, codProv: 11, label: "Puesto del Marqués" },
    { value: 1318, codProv: 11, label: "Puesto Viejo" },
    { value: 1319, codProv: 11, label: "Pumahuasi" },
    { value: 1320, codProv: 11, label: "Purmamarca" },
    { value: 1321, codProv: 11, label: "Rinconada" },
    { value: 1322, codProv: 11, label: "Rodeitos" },
    { value: 1323, codProv: 11, label: "Rosario de Río Grande" },
    { value: 1324, codProv: 11, label: "San Antonio" },
    { value: 1325, codProv: 11, label: "San Francisco" },
    { value: 1326, codProv: 11, label: "San Pedro" },
    { value: 1327, codProv: 11, label: "San Rafael" },
    { value: 1328, codProv: 11, label: "San Salvador" },
    { value: 1329, codProv: 11, label: "Santa Ana" },
    { value: 1330, codProv: 11, label: "Santa Catalina" },
    { value: 1331, codProv: 11, label: "Santa Clara" },
    { value: 1332, codProv: 11, label: "Susques" },
    { value: 1333, codProv: 11, label: "Tilcara" },
    { value: 1334, codProv: 11, label: "Tres Cruces" },
    { value: 1335, codProv: 11, label: "Tumbaya" },
    { value: 1336, codProv: 11, label: "Valle Grande" },
    { value: 1337, codProv: 11, label: "Vinalito" },
    { value: 1338, codProv: 11, label: "Volcán" },
    { value: 1339, codProv: 11, label: "Yala" },
    { value: 1340, codProv: 11, label: "Yaví" },
    { value: 1341, codProv: 11, label: "Yuto" },
    { value: 1342, codProv: 12, label: "Abramo" },
    { value: 1343, codProv: 12, label: "Adolfo Van Praet" },
    { value: 1344, codProv: 12, label: "Agustoni" },
    { value: 1345, codProv: 12, label: "Algarrobo del Aguila" },
    { value: 1346, codProv: 12, label: "Alpachiri" },
    { value: 1347, codProv: 12, label: "Alta Italia" },
    { value: 1348, codProv: 12, label: "Anguil" },
    { value: 1349, codProv: 12, label: "Arata" },
    { value: 1350, codProv: 12, label: "Ataliva Roca" },
    { value: 1351, codProv: 12, label: "Bernardo Larroude" },
    { value: 1352, codProv: 12, label: "Bernasconi" },
    { value: 1353, codProv: 12, label: "Caleufú" },
    { value: 1354, codProv: 12, label: "Carro Quemado" },
    { value: 1355, codProv: 12, label: "Catriló" },
    { value: 1356, codProv: 12, label: "Ceballos" },
    { value: 1357, codProv: 12, label: "Chacharramendi" },
    { value: 1358, codProv: 12, label: "Col. Barón" },
    { value: 1359, codProv: 12, label: "Col. Santa María" },
    { value: 1360, codProv: 12, label: "Conhelo" },
    { value: 1361, codProv: 12, label: "Coronel Hilario Lagos" },
    { value: 1362, codProv: 12, label: "Cuchillo-Có" },
    { value: 1363, codProv: 12, label: "Doblas" },
    { value: 1364, codProv: 12, label: "Dorila" },
    { value: 1365, codProv: 12, label: "Eduardo Castex" },
    { value: 1366, codProv: 12, label: "Embajador Martini" },
    { value: 1367, codProv: 12, label: "Falucho" },
    { value: 1368, codProv: 12, label: "Gral. Acha" },
    { value: 1369, codProv: 12, label: "Gral. Manuel Campos" },
    { value: 1370, codProv: 12, label: "Gral. Pico" },
    { value: 1371, codProv: 12, label: "Guatraché" },
    { value: 1372, codProv: 12, label: "Ing. Luiggi" },
    { value: 1373, codProv: 12, label: "Intendente Alvear" },
    { value: 1374, codProv: 12, label: "Jacinto Arauz" },
    { value: 1375, codProv: 12, label: "La Adela" },
    { value: 1376, codProv: 12, label: "La Humada" },
    { value: 1377, codProv: 12, label: "La Maruja" },
    { value: 1378, codProv: 12, label: "12" },
    { value: 1379, codProv: 12, label: "La Reforma" },
    { value: 1380, codProv: 12, label: "Limay Mahuvaluea" },
    { value: 1381, codProv: 12, label: "Lonquimay" },
    { value: 1382, codProv: 12, label: "Loventuel" },
    { value: 1383, codProv: 12, label: "Luan Toro" },
    { value: 1384, codProv: 12, label: "Macachín" },
    { value: 1385, codProv: 12, label: "Maisonnave" },
    { value: 1386, codProv: 12, label: "Mauricio Mayer" },
    { value: 1387, codProv: 12, label: "Metileo" },
    { value: 1388, codProv: 12, label: "Miguel Cané" },
    { value: 1389, codProv: 12, label: "Miguel Riglos" },
    { value: 1390, codProv: 12, label: "Monte Nievas" },
    { value: 1391, codProv: 12, label: "Parera" },
    { value: 1392, codProv: 12, label: "Perú" },
    { value: 1393, codProv: 12, label: "Pichi-Huinca" },
    { value: 1394, codProv: 12, label: "Puelches" },
    { value: 1395, codProv: 12, label: "Puelén" },
    { value: 1396, codProv: 12, label: "Quehue" },
    { value: 1397, codProv: 12, label: "Quemú Quemú" },
    { value: 1398, codProv: 12, label: "Quetrequén" },
    { value: 1399, codProv: 12, label: "Rancul" },
    { value: 1400, codProv: 12, label: "Realicó" },
    { value: 1401, codProv: 12, label: "Relmo" },
    { value: 1402, codProv: 12, label: "Rolón" },
    { value: 1403, codProv: 12, label: "Rucanelo" },
    { value: 1404, codProv: 12, label: "Sarah" },
    { value: 1405, codProv: 12, label: "Speluzzi" },
    { value: 1406, codProv: 12, label: "Sta. Isabel" },
    { value: 1407, codProv: 12, label: "Sta. Rosa" },
    { value: 1408, codProv: 12, label: "Sta. Teresa" },
    { value: 1409, codProv: 12, label: "Telén" },
    { value: 1410, codProv: 12, label: "Toay" },
    { value: 1411, codProv: 12, label: "Tomas M. de Anchorena" },
    { value: 1412, codProv: 12, label: "Trenel" },
    { value: 1413, codProv: 12, label: "Unanue" },
    { value: 1414, codProv: 12, label: "Uriburu" },
    { value: 1415, codProv: 12, label: "Veinticinco de Mayo" },
    { value: 1416, codProv: 12, label: "Vertiz" },
    { value: 1417, codProv: 12, label: "Victorica" },
    { value: 1418, codProv: 12, label: "Villa Mirasol" },
    { value: 1419, codProv: 12, label: "Winifreda" },
    { value: 1420, codProv: 13, label: "Arauco" },
    { value: 1421, codProv: 13, label: "Capital" },
    { value: 1422, codProv: 13, label: "Castro Barros" },
    { value: 1423, codProv: 13, label: "Chamical" },
    { value: 1424, codProv: 13, label: "Chilecito" },
    { value: 1425, codProv: 13, label: "Coronel F. Varela" },
    { value: 1426, codProv: 13, label: "Famatina" },
    { value: 1427, codProv: 13, label: "Gral. A.V.Peñaloza" },
    { value: 1428, codProv: 13, label: "Gral. Belgrano" },
    { value: 1429, codProv: 13, label: "Gral. J.F. Quiroga" },
    { value: 1430, codProv: 13, label: "Gral. Lamadrvalue" },
    { value: 1431, codProv: 13, label: "Gral. Ocampo" },
    { value: 1432, codProv: 13, label: "Gral. San Martín" },
    { value: 1433, codProv: 13, label: "Independencia" },
    { value: 1434, codProv: 13, label: "Rosario Penaloza" },
    { value: 1435, codProv: 13, label: "San Blas de Los Sauces" },
    { value: 1436, codProv: 13, label: "Sanagasta" },
    { value: 1437, codProv: 13, label: "Vinchina" },
    { value: 1438, codProv: 14, label: "Capital" },
    { value: 1439, codProv: 14, label: "Chacras de Coria" },
    { value: 1440, codProv: 14, label: "Dorrego" },
    { value: 1441, codProv: 14, label: "Gllen" },
    { value: 1442, codProv: 14, label: "Godoy Cruz" },
    { value: 1443, codProv: 14, label: "Gral. Alvear" },
    { value: 1444, codProv: 14, label: "Guaymallén" },
    { value: 1445, codProv: 14, label: "Junín" },
    { value: 1446, codProv: 14, label: "La Paz" },
    { value: 1447, codProv: 14, label: "Las Heras" },
    { value: 1448, codProv: 14, label: "Lavalle" },
    { value: 1449, codProv: 14, label: "Luján" },
    { value: 1450, codProv: 14, label: "Luján De Cuyo" },
    { value: 1451, codProv: 14, label: "Maipú" },
    { value: 1452, codProv: 14, label: "Malargüe" },
    { value: 1453, codProv: 14, label: "Rivadavia" },
    { value: 1454, codProv: 14, label: "San Carlos" },
    { value: 1455, codProv: 14, label: "San Martín" },
    { value: 1456, codProv: 14, label: "San Rafael" },
    { value: 1457, codProv: 14, label: "Sta. Rosa" },
    { value: 1458, codProv: 14, label: "Tunuyán" },
    { value: 1459, codProv: 14, label: "Tupungato" },
    { value: 1460, codProv: 14, label: "Villa Nueva" },
    { value: 1461, codProv: 15, label: "Alba Posse" },
    { value: 1462, codProv: 15, label: "Almafuerte" },
    { value: 1463, codProv: 15, label: "Apóstoles" },
    { value: 1464, codProv: 15, label: "Aristóbulo Del Valle" },
    { value: 1465, codProv: 15, label: "Arroyo Del Medio" },
    { value: 1466, codProv: 15, label: "Azara" },
    { value: 1467, codProv: 15, label: "Bdo. De Irigoyen" },
    { value: 1468, codProv: 15, label: "Bonpland" },
    { value: 1469, codProv: 15, label: "Caá Yari" },
    { value: 1470, codProv: 15, label: "Campo Grande" },
    { value: 1471, codProv: 15, label: "Campo Ramón" },
    { value: 1472, codProv: 15, label: "Campo Viera" },
    { value: 1473, codProv: 15, label: "Candelaria" },
    { value: 1474, codProv: 15, label: "Capioví" },
    { value: 1475, codProv: 15, label: "Caraguatay" },
    { value: 1476, codProv: 15, label: "Cdte. Guacurarí" },
    { value: 1477, codProv: 15, label: "Cerro Azul" },
    { value: 1478, codProv: 15, label: "Cerro Corá" },
    { value: 1479, codProv: 15, label: "Col. Alberdi" },
    { value: 1480, codProv: 15, label: "Col. Aurora" },
    { value: 1481, codProv: 15, label: "Col. Delicia" },
    { value: 1482, codProv: 15, label: "Col. Polana" },
    { value: 1483, codProv: 15, label: "Col. Victoria" },
    { value: 1484, codProv: 15, label: "Col. Wanda" },
    { value: 1485, codProv: 15, label: "Concepción De La Sierra" },
    { value: 1486, codProv: 15, label: "Corpus" },
    { value: 1487, codProv: 15, label: "Dos Arroyos" },
    { value: 1488, codProv: 15, label: "Dos de Mayo" },
    { value: 1489, codProv: 15, label: "El Alcázar" },
    { value: 1490, codProv: 15, label: "El Dorado" },
    { value: 1491, codProv: 15, label: "El Soberbio" },
    { value: 1492, codProv: 15, label: "Esperanza" },
    { value: 1493, codProv: 15, label: "F. Ameghino" },
    { value: 1494, codProv: 15, label: "Fachinal" },
    { value: 1495, codProv: 15, label: "Garuhapé" },
    { value: 1496, codProv: 15, label: "Garupá" },
    { value: 1497, codProv: 15, label: "Gdor. López" },
    { value: 1498, codProv: 15, label: "Gdor. Roca" },
    { value: 1499, codProv: 15, label: "Gral. Alvear" },
    { value: 1500, codProv: 15, label: "Gral. Urquiza" },
    { value: 1501, codProv: 15, label: "Guaraní" },
    { value: 1502, codProv: 15, label: "H. Yrigoyen" },
    { value: 1503, codProv: 15, label: "Iguazú" },
    { value: 1504, codProv: 15, label: "Itacaruaré" },
    { value: 1505, codProv: 15, label: "Jardín América" },
    { value: 1506, codProv: 15, label: "Leandro N. Alem" },
    { value: 1507, codProv: 15, label: "Libertad" },
    { value: 1508, codProv: 15, label: "Loreto" },
    { value: 1509, codProv: 15, label: "Los Helechos" },
    { value: 1510, codProv: 15, label: "Mártires" },
    { value: 1511, codProv: 15, label: "15" },
    { value: 1512, codProv: 15, label: "Mojón Grande" },
    { value: 1513, codProv: 15, label: "Montecarlo" },
    { value: 1514, codProv: 15, label: "Nueve de Julio" },
    { value: 1515, codProv: 15, label: "Oberá" },
    { value: 1516, codProv: 15, label: "Olegario V. Andrade" },
    { value: 1517, codProv: 15, label: "Panambí" },
    { value: 1518, codProv: 15, label: "Posadas" },
    { value: 1519, codProv: 15, label: "Profundvaluead" },
    { value: 1520, codProv: 15, label: "Pto. Iguazú" },
    { value: 1521, codProv: 15, label: "Pto. Leoni" },
    { value: 1522, codProv: 15, label: "Pto. Piray" },
    { value: 1523, codProv: 15, label: "Pto. Rico" },
    { value: 1524, codProv: 15, label: "Ruiz de Montoya" },
    { value: 1525, codProv: 15, label: "San Antonio" },
    { value: 1526, codProv: 15, label: "San Ignacio" },
    { value: 1527, codProv: 15, label: "San Javier" },
    { value: 1528, codProv: 15, label: "San José" },
    { value: 1529, codProv: 15, label: "San Martín" },
    { value: 1530, codProv: 15, label: "San Pedro" },
    { value: 1531, codProv: 15, label: "San Vicente" },
    { value: 1532, codProv: 15, label: "Santiago De Liniers" },
    { value: 1533, codProv: 15, label: "Santo Pipo" },
    { value: 1534, codProv: 15, label: "Sta. Ana" },
    { value: 1535, codProv: 15, label: "Sta. María" },
    { value: 1536, codProv: 15, label: "Tres Capones" },
    { value: 1537, codProv: 15, label: "Veinticinco de Mayo" },
    { value: 1538, codProv: 15, label: "Wanda" },
    { value: 1539, codProv: 16, label: "Aguada San Roque" },
    { value: 1540, codProv: 16, label: "Aluminé" },
    { value: 1541, codProv: 16, label: "Andacollo" },
    { value: 1542, codProv: 16, label: "Añelo" },
    { value: 1543, codProv: 16, label: "Bajada del Agrio" },
    { value: 1544, codProv: 16, label: "Barrancas" },
    { value: 1545, codProv: 16, label: "Buta Ranquil" },
    { value: 1546, codProv: 16, label: "Capital" },
    { value: 1547, codProv: 16, label: "Caviahué" },
    { value: 1548, codProv: 16, label: "Centenario" },
    { value: 1549, codProv: 16, label: "Chorriaca" },
    { value: 1550, codProv: 16, label: "Chos Malal" },
    { value: 1551, codProv: 16, label: "Cipolletti" },
    { value: 1552, codProv: 16, label: "Covunco Abajo" },
    { value: 1553, codProv: 16, label: "Coyuco Cochico" },
    { value: 1554, codProv: 16, label: "Cutral Có" },
    { value: 1555, codProv: 16, label: "El Cholar" },
    { value: 1556, codProv: 16, label: "El Huecú" },
    { value: 1557, codProv: 16, label: "El Sauce" },
    { value: 1558, codProv: 16, label: "Guañacos" },
    { value: 1559, codProv: 16, label: "Huinganco" },
    { value: 1560, codProv: 16, label: "Las Coloradas" },
    { value: 1561, codProv: 16, label: "Las Lajas" },
    { value: 1562, codProv: 16, label: "Las Ovejas" },
    { value: 1563, codProv: 16, label: "Loncopué" },
    { value: 1564, codProv: 16, label: "Los Catutos" },
    { value: 1565, codProv: 16, label: "Los Chihuvalueos" },
    { value: 1566, codProv: 16, label: "Los Miches" },
    { value: 1567, codProv: 16, label: "Manzano Amargo" },
    { value: 1568, codProv: 16, label: "16" },
    { value: 1569, codProv: 16, label: "Octavio Pico" },
    { value: 1570, codProv: 16, label: "Paso Aguerre" },
    { value: 1571, codProv: 16, label: "Picún Leufú" },
    { value: 1572, codProv: 16, label: "Piedra del Aguila" },
    { value: 1573, codProv: 16, label: "Pilo Lil" },
    { value: 1574, codProv: 16, label: "Plaza Huincul" },
    { value: 1575, codProv: 16, label: "Plottier" },
    { value: 1576, codProv: 16, label: "Quili Malal" },
    { value: 1577, codProv: 16, label: "Ramón Castro" },
    { value: 1578, codProv: 16, label: "Rincón de Los Sauces" },
    { value: 1579, codProv: 16, label: "San Martín de Los Andes" },
    { value: 1580, codProv: 16, label: "San Patricio del Chañar" },
    { value: 1581, codProv: 16, label: "Santo Tomás" },
    { value: 1582, codProv: 16, label: "Sauzal Bonito" },
    { value: 1583, codProv: 16, label: "Senillosa" },
    { value: 1584, codProv: 16, label: "Taquimilán" },
    { value: 1585, codProv: 16, label: "Tricao Malal" },
    { value: 1586, codProv: 16, label: "Varvarco" },
    { value: 1587, codProv: 16, label: "Villa Curí Leuvu" },
    { value: 1588, codProv: 16, label: "Villa del Nahueve" },
    { value: 1589, codProv: 16, label: "Villa del Puente Picún Leuvú" },
    { value: 1590, codProv: 16, label: "Villa El Chocón" },
    { value: 1591, codProv: 16, label: "Villa La Angostura" },
    { value: 1592, codProv: 16, label: "Villa Pehuenia" },
    { value: 1593, codProv: 16, label: "Villa Traful" },
    { value: 1594, codProv: 16, label: "Vista Alegre" },
    { value: 1595, codProv: 16, label: "Zapala" },
    { value: 1596, codProv: 17, label: "Aguada Cecilio" },
    { value: 1597, codProv: 17, label: "Aguada de Guerra" },
    { value: 1598, codProv: 17, label: "Allén" },
    { value: 1599, codProv: 17, label: "Arroyo de La Ventana" },
    { value: 1600, codProv: 17, label: "Arroyo Los Berros" },
    { value: 1601, codProv: 17, label: "Bariloche" },
    { value: 1602, codProv: 17, label: "Calte. Cordero" },
    { value: 1603, codProv: 17, label: "Campo Grande" },
    { value: 1604, codProv: 17, label: "Catriel" },
    { value: 1605, codProv: 17, label: "Cerro Policía" },
    { value: 1606, codProv: 17, label: "Cervantes" },
    { value: 1607, codProv: 17, label: "Chelforo" },
    { value: 1608, codProv: 17, label: "Chimpay" },
    { value: 1609, codProv: 17, label: "Chinchinales" },
    { value: 1610, codProv: 17, label: "Chipauquil" },
    { value: 1611, codProv: 17, label: "Choele Choel" },
    { value: 1612, codProv: 17, label: "Cinco Saltos" },
    { value: 1613, codProv: 17, label: "Cipolletti" },
    { value: 1614, codProv: 17, label: "Clemente Onelli" },
    { value: 1615, codProv: 17, label: "Colán Conhue" },
    { value: 1616, codProv: 17, label: "Comallo" },
    { value: 1617, codProv: 17, label: "Comicó" },
    { value: 1618, codProv: 17, label: "Cona Niyeu" },
    { value: 1619, codProv: 17, label: "Coronel Belisle" },
    { value: 1620, codProv: 17, label: "Cubanea" },
    { value: 1621, codProv: 17, label: "Darwin" },
    { value: 1622, codProv: 17, label: "Dina Huapi" },
    { value: 1623, codProv: 17, label: "El Bolsón" },
    { value: 1624, codProv: 17, label: "El Caín" },
    { value: 1625, codProv: 17, label: "El Manso" },
    { value: 1626, codProv: 17, label: "Gral. Conesa" },
    { value: 1627, codProv: 17, label: "Gral. Enrique Godoy" },
    { value: 1628, codProv: 17, label: "Gral. Fernandez Oro" },
    { value: 1629, codProv: 17, label: "Gral. Roca" },
    { value: 1630, codProv: 17, label: "Guardia Mitre" },
    { value: 1631, codProv: 17, label: "Ing. Huergo" },
    { value: 1632, codProv: 17, label: "Ing. Jacobacci" },
    { value: 1633, codProv: 17, label: "Laguna Blanca" },
    { value: 1634, codProv: 17, label: "Lamarque" },
    { value: 1635, codProv: 17, label: "Las Grutas" },
    { value: 1636, codProv: 17, label: "Los Menucos" },
    { value: 1637, codProv: 17, label: "Luis Beltrán" },
    { value: 1638, codProv: 17, label: "Mainqué" },
    { value: 1639, codProv: 17, label: "Mamuel Choique" },
    { value: 1640, codProv: 17, label: "Maquinchao" },
    { value: 1641, codProv: 17, label: "Mencué" },
    { value: 1642, codProv: 17, label: "Mtro. Ramos Mexia" },
    { value: 1643, codProv: 17, label: "Nahuel Niyeu" },
    { value: 1644, codProv: 17, label: "Naupa Huen" },
    { value: 1645, codProv: 17, label: "Ñorquinco" },
    { value: 1646, codProv: 17, label: "Ojos de Agua" },
    { value: 1647, codProv: 17, label: "Paso de Agua" },
    { value: 1648, codProv: 17, label: "Paso Flores" },
    { value: 1649, codProv: 17, label: "Peñas Blancas" },
    { value: 1650, codProv: 17, label: "Pichi Mahuvaluea" },
    { value: 1651, codProv: 17, label: "Pilcaniyeu" },
    { value: 1652, codProv: 17, label: "Pomona" },
    { value: 1653, codProv: 17, label: "Prahuaniyeu" },
    { value: 1654, codProv: 17, label: "Rincón Treneta" },
    { value: 1655, codProv: 17, label: "Río Chico" },
    { value: 1656, codProv: 17, label: "Río Colorado" },
    { value: 1657, codProv: 17, label: "Roca" },
    { value: 1658, codProv: 17, label: "San Antonio Oeste" },
    { value: 1659, codProv: 17, label: "San Javier" },
    { value: 1660, codProv: 17, label: "Sierra Colorada" },
    { value: 1661, codProv: 17, label: "Sierra Grande" },
    { value: 1662, codProv: 17, label: "Sierra Pailemán" },
    { value: 1663, codProv: 17, label: "Valcheta" },
    { value: 1664, codProv: 17, label: "Valle Azul" },
    { value: 1665, codProv: 17, label: "Viedma" },
    { value: 1666, codProv: 17, label: "Villa Llanquín" },
    { value: 1667, codProv: 17, label: "Villa Mascardi" },
    { value: 1668, codProv: 17, label: "Villa Regina" },
    { value: 1669, codProv: 17, label: "Yaminué" },
    { value: 1670, codProv: 18, label: "A. Saravia" },
    { value: 1671, codProv: 18, label: "Aguaray" },
    { value: 1672, codProv: 18, label: "Angastaco" },
    { value: 1673, codProv: 18, label: "Animaná" },
    { value: 1674, codProv: 18, label: "Cachi" },
    { value: 1675, codProv: 18, label: "Cafayate" },
    { value: 1676, codProv: 18, label: "Campo Quijano" },
    { value: 1677, codProv: 18, label: "Campo Santo" },
    { value: 1678, codProv: 18, label: "Capital" },
    { value: 1679, codProv: 18, label: "Cerrillos" },
    { value: 1680, codProv: 18, label: "Chicoana" },
    { value: 1681, codProv: 18, label: "Col. Sta. Rosa" },
    { value: 1682, codProv: 18, label: "Coronel Moldes" },
    { value: 1683, codProv: 18, label: "El Bordo" },
    { value: 1684, codProv: 18, label: "El Carril" },
    { value: 1685, codProv: 18, label: "El Galpón" },
    { value: 1686, codProv: 18, label: "El Jardín" },
    { value: 1687, codProv: 18, label: "El Potrero" },
    { value: 1688, codProv: 18, label: "El Quebrachal" },
    { value: 1689, codProv: 18, label: "El Tala" },
    { value: 1690, codProv: 18, label: "Embarcación" },
    { value: 1691, codProv: 18, label: "Gral. Ballivian" },
    { value: 1692, codProv: 18, label: "Gral. Güemes" },
    { value: 1693, codProv: 18, label: "Gral. Mosconi" },
    { value: 1694, codProv: 18, label: "Gral. Pizarro" },
    { value: 1695, codProv: 18, label: "Guachipas" },
    { value: 1696, codProv: 18, label: "Hipólito Yrigoyen" },
    { value: 1697, codProv: 18, label: "Iruyá" },
    { value: 1698, codProv: 18, label: "Isla De Cañas" },
    { value: 1699, codProv: 18, label: "J. V. Gonzalez" },
    { value: 1700, codProv: 18, label: "La Caldera" },
    { value: 1701, codProv: 18, label: "La Candelaria" },
    { value: 1702, codProv: 18, label: "La Merced" },
    { value: 1703, codProv: 18, label: "La Poma" },
    { value: 1704, codProv: 18, label: "La Viña" },
    { value: 1705, codProv: 18, label: "Las Lajitas" },
    { value: 1706, codProv: 18, label: "Los Toldos" },
    { value: 1707, codProv: 18, label: "Metán" },
    { value: 1708, codProv: 18, label: "Molinos" },
    { value: 1709, codProv: 18, label: "Nazareno" },
    { value: 1710, codProv: 18, label: "Orán" },
    { value: 1711, codProv: 18, label: "Payogasta" },
    { value: 1712, codProv: 18, label: "Pichanal" },
    { value: 1713, codProv: 18, label: "Prof. S. Mazza" },
    { value: 1714, codProv: 18, label: "Río Piedras" },
    { value: 1715, codProv: 18, label: "Rivadavia Banda Norte" },
    { value: 1716, codProv: 18, label: "Rivadavia Banda Sur" },
    { value: 1717, codProv: 18, label: "Rosario de La Frontera" },
    { value: 1718, codProv: 18, label: "Rosario de Lerma" },
    { value: 1719, codProv: 18, label: "Saclantás" },
    { value: 1720, codProv: 18, label: "18" },
    { value: 1721, codProv: 18, label: "San Antonio" },
    { value: 1722, codProv: 18, label: "San Carlos" },
    { value: 1723, codProv: 18, label: "San José De Metán" },
    { value: 1724, codProv: 18, label: "San Ramón" },
    { value: 1725, codProv: 18, label: "Santa Victoria E." },
    { value: 1726, codProv: 18, label: "Santa Victoria O." },
    { value: 1727, codProv: 18, label: "Tartagal" },
    { value: 1728, codProv: 18, label: "Tolar Grande" },
    { value: 1729, codProv: 18, label: "Urundel" },
    { value: 1730, codProv: 18, label: "Vaqueros" },
    { value: 1731, codProv: 18, label: "Villa San Lorenzo" },
    { value: 1732, codProv: 19, label: "Albardón" },
    { value: 1733, codProv: 19, label: "Angaco" },
    { value: 1734, codProv: 19, label: "Calingasta" },
    { value: 1735, codProv: 19, label: "Capital" },
    { value: 1736, codProv: 19, label: "Caucete" },
    { value: 1737, codProv: 19, label: "Chimbas" },
    { value: 1738, codProv: 19, label: "Iglesia" },
    { value: 1739, codProv: 19, label: "Jachal" },
    { value: 1740, codProv: 19, label: "Nueve de Julio" },
    { value: 1741, codProv: 19, label: "Pocito" },
    { value: 1742, codProv: 19, label: "Rawson" },
    { value: 1743, codProv: 19, label: "Rivadavia" },
    { value: 1744, codProv: 19, label: "19" },
    { value: 1745, codProv: 19, label: "San Martín" },
    { value: 1746, codProv: 19, label: "Santa Lucía" },
    { value: 1747, codProv: 19, label: "Sarmiento" },
    { value: 1748, codProv: 19, label: "Ullum" },
    { value: 1749, codProv: 19, label: "Valle Fértil" },
    { value: 1750, codProv: 19, label: "Veinticinco de Mayo" },
    { value: 1751, codProv: 19, label: "Zonda" },
    { value: 1752, codProv: 20, label: "Alto Pelado" },
    { value: 1753, codProv: 20, label: "Alto Pencoso" },
    { value: 1754, codProv: 20, label: "Anchorena" },
    { value: 1755, codProv: 20, label: "Arizona" },
    { value: 1756, codProv: 20, label: "Bagual" },
    { value: 1757, codProv: 20, label: "Balde" },
    { value: 1758, codProv: 20, label: "Batavia" },
    { value: 1759, codProv: 20, label: "Beazley" },
    { value: 1760, codProv: 20, label: "Buena Esperanza" },
    { value: 1761, codProv: 20, label: "Candelaria" },
    { value: 1762, codProv: 20, label: "Capital" },
    { value: 1763, codProv: 20, label: "Carolina" },
    { value: 1764, codProv: 20, label: "Carpintería" },
    { value: 1765, codProv: 20, label: "Concarán" },
    { value: 1766, codProv: 20, label: "Cortaderas" },
    { value: 1767, codProv: 20, label: "El Morro" },
    { value: 1768, codProv: 20, label: "El Trapiche" },
    { value: 1769, codProv: 20, label: "El Volcán" },
    { value: 1770, codProv: 20, label: "Fortín El Patria" },
    { value: 1771, codProv: 20, label: "Fortuna" },
    { value: 1772, codProv: 20, label: "Fraga" },
    { value: 1773, codProv: 20, label: "Juan Jorba" },
    { value: 1774, codProv: 20, label: "Juan Llerena" },
    { value: 1775, codProv: 20, label: "Juana Koslay" },
    { value: 1776, codProv: 20, label: "Justo Daract" },
    { value: 1777, codProv: 20, label: "La Calera" },
    { value: 1778, codProv: 20, label: "La Florvaluea" },
    { value: 1779, codProv: 20, label: "La Punilla" },
    { value: 1780, codProv: 20, label: "La Toma" },
    { value: 1781, codProv: 20, label: "Lafinur" },
    { value: 1782, codProv: 20, label: "Las Aguadas" },
    { value: 1783, codProv: 20, label: "Las Chacras" },
    { value: 1784, codProv: 20, label: "Las Lagunas" },
    { value: 1785, codProv: 20, label: "Las Vertientes" },
    { value: 1786, codProv: 20, label: "Lavaisse" },
    { value: 1787, codProv: 20, label: "Leandro N. Alem" },
    { value: 1788, codProv: 20, label: "Los Molles" },
    { value: 1789, codProv: 20, label: "Luján" },
    { value: 1790, codProv: 20, label: "Mercedes" },
    { value: 1791, codProv: 20, label: "Merlo" },
    { value: 1792, codProv: 20, label: "Naschel" },
    { value: 1793, codProv: 20, label: "Navia" },
    { value: 1794, codProv: 20, label: "Nogolí" },
    { value: 1795, codProv: 20, label: "Nueva Galia" },
    { value: 1796, codProv: 20, label: "Papagayos" },
    { value: 1797, codProv: 20, label: "Paso Grande" },
    { value: 1798, codProv: 20, label: "Potrero de Los Funes" },
    { value: 1799, codProv: 20, label: "Quines" },
    { value: 1800, codProv: 20, label: "Renca" },
    { value: 1801, codProv: 20, label: "Saladillo" },
    { value: 1802, codProv: 20, label: "San Francisco" },
    { value: 1803, codProv: 20, label: "San Gerónimo" },
    { value: 1804, codProv: 20, label: "San Martín" },
    { value: 1805, codProv: 20, label: "San Pablo" },
    { value: 1806, codProv: 20, label: "Santa Rosa de Conlara" },
    { value: 1807, codProv: 20, label: "Talita" },
    { value: 1808, codProv: 20, label: "Tilisarao" },
    { value: 1809, codProv: 20, label: "Unión" },
    { value: 1810, codProv: 20, label: "Villa de La Quebrada" },
    { value: 1811, codProv: 20, label: "Villa de Praga" },
    { value: 1812, codProv: 20, label: "Villa del Carmen" },
    { value: 1813, codProv: 20, label: "Villa Gral. Roca" },
    { value: 1814, codProv: 20, label: "Villa Larca" },
    { value: 1815, codProv: 20, label: "Villa Mercedes" },
    { value: 1816, codProv: 20, label: "Zanjitas" },
    { value: 1817, codProv: 21, label: "Calafate" },
    { value: 1818, codProv: 21, label: "Caleta Olivia" },
    { value: 1819, codProv: 21, label: "Cañadón Seco" },
    { value: 1820, codProv: 21, label: "Comandante Piedrabuena" },
    { value: 1821, codProv: 21, label: "El Calafate" },
    { value: 1822, codProv: 21, label: "El Chaltén" },
    { value: 1823, codProv: 21, label: "Gdor. Gregores" },
    { value: 1824, codProv: 21, label: "Hipólito Yrigoyen" },
    { value: 1825, codProv: 21, label: "Jaramillo" },
    { value: 1826, codProv: 21, label: "Koluel Kaike" },
    { value: 1827, codProv: 21, label: "Las Heras" },
    { value: 1828, codProv: 21, label: "Los Antiguos" },
    { value: 1829, codProv: 21, label: "Perito Moreno" },
    { value: 1830, codProv: 21, label: "Pico Truncado" },
    { value: 1831, codProv: 21, label: "Pto. Deseado" },
    { value: 1832, codProv: 21, label: "Pto. San Julián" },
    { value: 1833, codProv: 21, label: "Pto. 21" },
    { value: 1834, codProv: 21, label: "Río Cuarto" },
    { value: 1835, codProv: 21, label: "Río Gallegos" },
    { value: 1836, codProv: 21, label: "Río Turbio" },
    { value: 1837, codProv: 21, label: "Tres Lagos" },
    { value: 1838, codProv: 21, label: "Veintiocho De Noviembre" },
    { value: 1839, codProv: 22, label: "Aarón Castellanos" },
    { value: 1840, codProv: 22, label: "Acebal" },
    { value: 1841, codProv: 22, label: "Aguará Grande" },
    { value: 1842, codProv: 22, label: "Albarellos" },
    { value: 1843, codProv: 22, label: "Alcorta" },
    { value: 1844, codProv: 22, label: "Aldao" },
    { value: 1845, codProv: 22, label: "Alejandra" },
    { value: 1846, codProv: 22, label: "Álvarez" },
    { value: 1847, codProv: 22, label: "Ambrosetti" },
    { value: 1848, codProv: 22, label: "Amenábar" },
    { value: 1849, codProv: 22, label: "Angélica" },
    { value: 1850, codProv: 22, label: "Angeloni" },
    { value: 1851, codProv: 22, label: "Arequito" },
    { value: 1852, codProv: 22, label: "Arminda" },
    { value: 1853, codProv: 22, label: "Armstrong" },
    { value: 1854, codProv: 22, label: "Arocena" },
    { value: 1855, codProv: 22, label: "Arroyo Aguiar" },
    { value: 1856, codProv: 22, label: "Arroyo Ceibal" },
    { value: 1857, codProv: 22, label: "Arroyo Leyes" },
    { value: 1858, codProv: 22, label: "Arroyo Seco" },
    { value: 1859, codProv: 22, label: "Arrufó" },
    { value: 1860, codProv: 22, label: "Arteaga" },
    { value: 1861, codProv: 22, label: "Ataliva" },
    { value: 1862, codProv: 22, label: "Aurelia" },
    { value: 1863, codProv: 22, label: "Avellaneda" },
    { value: 1864, codProv: 22, label: "Barrancas" },
    { value: 1865, codProv: 22, label: "Bauer Y Sigel" },
    { value: 1866, codProv: 22, label: "Bella Italia" },
    { value: 1867, codProv: 22, label: "Berabevú" },
    { value: 1868, codProv: 22, label: "Berna" },
    { value: 1869, codProv: 22, label: "Bernardo de Irigoyen" },
    { value: 1870, codProv: 22, label: "Bigand" },
    { value: 1871, codProv: 22, label: "Bombal" },
    { value: 1872, codProv: 22, label: "Bouquet" },
    { value: 1873, codProv: 22, label: "Bustinza" },
    { value: 1874, codProv: 22, label: "Cabal" },
    { value: 1875, codProv: 22, label: "Cacique Ariacaiquin" },
    { value: 1876, codProv: 22, label: "Cafferata" },
    { value: 1877, codProv: 22, label: "Calchaquí" },
    { value: 1878, codProv: 22, label: "Campo Andino" },
    { value: 1879, codProv: 22, label: "Campo Piaggio" },
    { value: 1880, codProv: 22, label: "Cañada de Gómez" },
    { value: 1881, codProv: 22, label: "Cañada del Ucle" },
    { value: 1882, codProv: 22, label: "Cañada Rica" },
    { value: 1883, codProv: 22, label: "Cañada Rosquín" },
    { value: 1884, codProv: 22, label: "Candioti" },
    { value: 1885, codProv: 22, label: "Capital" },
    { value: 1886, codProv: 22, label: "Capitán Bermúdez" },
    { value: 1887, codProv: 22, label: "Capivara" },
    { value: 1888, codProv: 22, label: "Carcarañá" },
    { value: 1889, codProv: 22, label: "Carlos Pellegrini" },
    { value: 1890, codProv: 22, label: "Carmen" },
    { value: 1891, codProv: 22, label: "Carmen Del Sauce" },
    { value: 1892, codProv: 22, label: "Carreras" },
    { value: 1893, codProv: 22, label: "Carrizales" },
    { value: 1894, codProv: 22, label: "Casalegno" },
    { value: 1895, codProv: 22, label: "Casas" },
    { value: 1896, codProv: 22, label: "Casilda" },
    { value: 1897, codProv: 22, label: "Castelar" },
    { value: 1898, codProv: 22, label: "Castellanos" },
    { value: 1899, codProv: 22, label: "Cayastá" },
    { value: 1900, codProv: 22, label: "Cayastacito" },
    { value: 1901, codProv: 22, label: "Centeno" },
    { value: 1902, codProv: 22, label: "Cepeda" },
    { value: 1903, codProv: 22, label: "Ceres" },
    { value: 1904, codProv: 22, label: "Chabás" },
    { value: 1905, codProv: 22, label: "Chañar Ladeado" },
    { value: 1906, codProv: 22, label: "Chapuy" },
    { value: 1907, codProv: 22, label: "Chovet" },
    { value: 1908, codProv: 22, label: "Christophersen" },
    { value: 1909, codProv: 22, label: "Classon" },
    { value: 1910, codProv: 22, label: "Cnel. Arnold" },
    { value: 1911, codProv: 22, label: "Cnel. Bogado" },
    { value: 1912, codProv: 22, label: "Cnel. Dominguez" },
    { value: 1913, codProv: 22, label: "Cnel. Fraga" },
    { value: 1914, codProv: 22, label: "Col. Aldao" },
    { value: 1915, codProv: 22, label: "Col. Ana" },
    { value: 1916, codProv: 22, label: "Col. Belgrano" },
    { value: 1917, codProv: 22, label: "Col. Bicha" },
    { value: 1918, codProv: 22, label: "Col. Bigand" },
    { value: 1919, codProv: 22, label: "Col. Bossi" },
    { value: 1920, codProv: 22, label: "Col. Cavour" },
    { value: 1921, codProv: 22, label: "Col. Cello" },
    { value: 1922, codProv: 22, label: "Col. Dolores" },
    { value: 1923, codProv: 22, label: "Col. Dos Rosas" },
    { value: 1924, codProv: 22, label: "Col. Durán" },
    { value: 1925, codProv: 22, label: "Col. Iturraspe" },
    { value: 1926, codProv: 22, label: "Col. Margarita" },
    { value: 1927, codProv: 22, label: "Col. Mascias" },
    { value: 1928, codProv: 22, label: "Col. Raquel" },
    { value: 1929, codProv: 22, label: "Col. Rosa" },
    { value: 1930, codProv: 22, label: "Col. San José" },
    { value: 1931, codProv: 22, label: "Constanza" },
    { value: 1932, codProv: 22, label: "Coronda" },
    { value: 1933, codProv: 22, label: "Correa" },
    { value: 1934, codProv: 22, label: "Crispi" },
    { value: 1935, codProv: 22, label: "Cululú" },
    { value: 1936, codProv: 22, label: "Curupayti" },
    { value: 1937, codProv: 22, label: "Desvio Arijón" },
    { value: 1938, codProv: 22, label: "Diaz" },
    { value: 1939, codProv: 22, label: "Diego de Alvear" },
    { value: 1940, codProv: 22, label: "Egusquiza" },
    { value: 1941, codProv: 22, label: "El Arazá" },
    { value: 1942, codProv: 22, label: "El Rabón" },
    { value: 1943, codProv: 22, label: "El Sombrerito" },
    { value: 1944, codProv: 22, label: "El Trébol" },
    { value: 1945, codProv: 22, label: "Elisa" },
    { value: 1946, codProv: 22, label: "Elortondo" },
    { value: 1947, codProv: 22, label: "Emilia" },
    { value: 1948, codProv: 22, label: "Empalme San Carlos" },
    { value: 1949, codProv: 22, label: "Empalme Villa Constitucion" },
    { value: 1950, codProv: 22, label: "Esmeralda" },
    { value: 1951, codProv: 22, label: "Esperanza" },
    { value: 1952, codProv: 22, label: "Estación Alvear" },
    { value: 1953, codProv: 22, label: "Estacion Clucellas" },
    { value: 1954, codProv: 22, label: "Esteban Rams" },
    { value: 1955, codProv: 22, label: "Esther" },
    { value: 1956, codProv: 22, label: "Esustolia" },
    { value: 1957, codProv: 22, label: "Eusebia" },
    { value: 1958, codProv: 22, label: "Felicia" },
    { value: 1959, codProv: 22, label: "Fvalueela" },
    { value: 1960, codProv: 22, label: "Fighiera" },
    { value: 1961, codProv: 22, label: "Firmat" },
    { value: 1962, codProv: 22, label: "Florencia" },
    { value: 1963, codProv: 22, label: "Fortín Olmos" },
    { value: 1964, codProv: 22, label: "Franck" },
    { value: 1965, codProv: 22, label: "Fray Luis Beltrán" },
    { value: 1966, codProv: 22, label: "Frontera" },
    { value: 1967, codProv: 22, label: "Fuentes" },
    { value: 1968, codProv: 22, label: "Funes" },
    { value: 1969, codProv: 22, label: "Gaboto" },
    { value: 1970, codProv: 22, label: "Galisteo" },
    { value: 1971, codProv: 22, label: "Gálvez" },
    { value: 1972, codProv: 22, label: "Garabalto" },
    { value: 1973, codProv: 22, label: "Garibaldi" },
    { value: 1974, codProv: 22, label: "Gato Colorado" },
    { value: 1975, codProv: 22, label: "Gdor. Crespo" },
    { value: 1976, codProv: 22, label: "Gessler" },
    { value: 1977, codProv: 22, label: "Godoy" },
    { value: 1978, codProv: 22, label: "Golondrina" },
    { value: 1979, codProv: 22, label: "Gral. Gelly" },
    { value: 1980, codProv: 22, label: "Gral. Lagos" },
    { value: 1981, codProv: 22, label: "Granadero Baigorria" },
    { value: 1982, codProv: 22, label: "Gregoria Perez De Denis" },
    { value: 1983, codProv: 22, label: "Grutly" },
    { value: 1984, codProv: 22, label: "Guadalupe N." },
    { value: 1985, codProv: 22, label: "Gödeken" },
    { value: 1986, codProv: 22, label: "Helvecia" },
    { value: 1987, codProv: 22, label: "Hersilia" },
    { value: 1988, codProv: 22, label: "Hipatía" },
    { value: 1989, codProv: 22, label: "Huanqueros" },
    { value: 1990, codProv: 22, label: "Hugentobler" },
    { value: 1991, codProv: 22, label: "Hughes" },
    { value: 1992, codProv: 22, label: "Humberto 1º" },
    { value: 1993, codProv: 22, label: "Humboldt" },
    { value: 1994, codProv: 22, label: "Ibarlucea" },
    { value: 1995, codProv: 22, label: "Ing. Chanourdie" },
    { value: 1996, codProv: 22, label: "Intiyaco" },
    { value: 1997, codProv: 22, label: "Ituzaingó" },
    { value: 1998, codProv: 22, label: "Jacinto L. Aráuz" },
    { value: 1999, codProv: 22, label: "Josefina" },
    { value: 2000, codProv: 22, label: "Juan B. Molina" },
    { value: 2001, codProv: 22, label: "Juan de Garay" },
    { value: 2002, codProv: 22, label: "Juncal" },
    { value: 2003, codProv: 22, label: "La Brava" },
    { value: 2004, codProv: 22, label: "La Cabral" },
    { value: 2005, codProv: 22, label: "La Camila" },
    { value: 2006, codProv: 22, label: "La Chispa" },
    { value: 2007, codProv: 22, label: "La Clara" },
    { value: 2008, codProv: 22, label: "La Criolla" },
    { value: 2009, codProv: 22, label: "La Gallareta" },
    { value: 2010, codProv: 22, label: "La Lucila" },
    { value: 2011, codProv: 22, label: "La Pelada" },
    { value: 2012, codProv: 22, label: "La Penca" },
    { value: 2013, codProv: 22, label: "La Rubia" },
    { value: 2014, codProv: 22, label: "La Sarita" },
    { value: 2015, codProv: 22, label: "La Vanguardia" },
    { value: 2016, codProv: 22, label: "Labordeboy" },
    { value: 2017, codProv: 22, label: "Laguna Paiva" },
    { value: 2018, codProv: 22, label: "Landeta" },
    { value: 2019, codProv: 22, label: "Lanteri" },
    { value: 2020, codProv: 22, label: "Larrechea" },
    { value: 2021, codProv: 22, label: "Las Avispas" },
    { value: 2022, codProv: 22, label: "Las Bandurrias" },
    { value: 2023, codProv: 22, label: "Las Garzas" },
    { value: 2024, codProv: 22, label: "Las Palmeras" },
    { value: 2025, codProv: 22, label: "Las Parejas" },
    { value: 2026, codProv: 22, label: "Las Petacas" },
    { value: 2027, codProv: 22, label: "Las Rosas" },
    { value: 2028, codProv: 22, label: "Las Toscas" },
    { value: 2029, codProv: 22, label: "Las Tunas" },
    { value: 2030, codProv: 22, label: "Lazzarino" },
    { value: 2031, codProv: 22, label: "Lehmann" },
    { value: 2032, codProv: 22, label: "Llambi Campbell" },
    { value: 2033, codProv: 22, label: "Logroño" },
    { value: 2034, codProv: 22, label: "Loma Alta" },
    { value: 2035, codProv: 22, label: "López" },
    { value: 2036, codProv: 22, label: "Los Amores" },
    { value: 2037, codProv: 22, label: "Los Cardos" },
    { value: 2038, codProv: 22, label: "Los Laureles" },
    { value: 2039, codProv: 22, label: "Los Molinos" },
    { value: 2040, codProv: 22, label: "Los Quirquinchos" },
    { value: 2041, codProv: 22, label: "Lucio V. Lopez" },
    { value: 2042, codProv: 22, label: "Luis Palacios" },
    { value: 2043, codProv: 22, label: "Ma. Juana" },
    { value: 2044, codProv: 22, label: "Ma. Luisa" },
    { value: 2045, codProv: 22, label: "Ma. Susana" },
    { value: 2046, codProv: 22, label: "Ma. Teresa" },
    { value: 2047, codProv: 22, label: "Maciel" },
    { value: 2048, codProv: 22, label: "Maggiolo" },
    { value: 2049, codProv: 22, label: "Malabrigo" },
    { value: 2050, codProv: 22, label: "Marcelino Escalada" },
    { value: 2051, codProv: 22, label: "Margarita" },
    { value: 2052, codProv: 22, label: "Matilde" },
    { value: 2053, codProv: 22, label: "Mauá" },
    { value: 2054, codProv: 22, label: "Máximo Paz" },
    { value: 2055, codProv: 22, label: "Melincué" },
    { value: 2056, codProv: 22, label: "Miguel Torres" },
    { value: 2057, codProv: 22, label: "Moisés Ville" },
    { value: 2058, codProv: 22, label: "Monigotes" },
    { value: 2059, codProv: 22, label: "Monje" },
    { value: 2060, codProv: 22, label: "Monte Obscurvaluead" },
    { value: 2061, codProv: 22, label: "Monte Vera" },
    { value: 2062, codProv: 22, label: "Montefiore" },
    { value: 2063, codProv: 22, label: "Montes de Oca" },
    { value: 2064, codProv: 22, label: "Murphy" },
    { value: 2065, codProv: 22, label: "Ñanducita" },
    { value: 2066, codProv: 22, label: "Naré" },
    { value: 2067, codProv: 22, label: "Nelson" },
    { value: 2068, codProv: 22, label: "Nicanor E. Molinas" },
    { value: 2069, codProv: 22, label: "Nuevo Torino" },
    { value: 2070, codProv: 22, label: "Oliveros" },
    { value: 2071, codProv: 22, label: "Palacios" },
    { value: 2072, codProv: 22, label: "Pavón" },
    { value: 2073, codProv: 22, label: "Pavón Arriba" },
    { value: 2074, codProv: 22, label: "Pedro Gómez Cello" },
    { value: 2075, codProv: 22, label: "Pérez" },
    { value: 2076, codProv: 22, label: "Peyrano" },
    { value: 2077, codProv: 22, label: "Piamonte" },
    { value: 2078, codProv: 22, label: "Pilar" },
    { value: 2079, codProv: 22, label: "Piñero" },
    { value: 2080, codProv: 22, label: "Plaza Clucellas" },
    { value: 2081, codProv: 22, label: "Portugalete" },
    { value: 2082, codProv: 22, label: "Pozo Borrado" },
    { value: 2083, codProv: 22, label: "Progreso" },
    { value: 2084, codProv: 22, label: "Provvalueencia" },
    { value: 2085, codProv: 22, label: "Pte. Roca" },
    { value: 2086, codProv: 22, label: "Pueblo Andino" },
    { value: 2087, codProv: 22, label: "Pueblo Esther" },
    { value: 2088, codProv: 22, label: "Pueblo Gral. San Martín" },
    { value: 2089, codProv: 22, label: "Pueblo Irigoyen" },
    { value: 2090, codProv: 22, label: "Pueblo Marini" },
    { value: 2091, codProv: 22, label: "Pueblo Muñoz" },
    { value: 2092, codProv: 22, label: "Pueblo Uranga" },
    { value: 2093, codProv: 22, label: "Pujato" },
    { value: 2094, codProv: 22, label: "Pujato N." },
    { value: 2095, codProv: 22, label: "Rafaela" },
    { value: 2096, codProv: 22, label: "Ramayón" },
    { value: 2097, codProv: 22, label: "Ramona" },
    { value: 2098, codProv: 22, label: "Reconquista" },
    { value: 2099, codProv: 22, label: "Recreo" },
    { value: 2100, codProv: 22, label: "Ricardone" },
    { value: 2101, codProv: 22, label: "Rivadavia" },
    { value: 2102, codProv: 22, label: "Roldán" },
    { value: 2103, codProv: 22, label: "Romang" },
    { value: 2104, codProv: 22, label: "Rosario" },
    { value: 2105, codProv: 22, label: "Rueda" },
    { value: 2106, codProv: 22, label: "Rufino" },
    { value: 2107, codProv: 22, label: "Sa Pereira" },
    { value: 2108, codProv: 22, label: "Saguier" },
    { value: 2109, codProv: 22, label: "Saladero M. Cabal" },
    { value: 2110, codProv: 22, label: "Salto Grande" },
    { value: 2111, codProv: 22, label: "San Agustín" },
    { value: 2112, codProv: 22, label: "San Antonio de Obligado" },
    { value: 2113, codProv: 22, label: "San Bernardo (N.J.)" },
    { value: 2114, codProv: 22, label: "San Bernardo (S.J.)" },
    { value: 2115, codProv: 22, label: "San Carlos Centro" },
    { value: 2116, codProv: 22, label: "San Carlos N." },
    { value: 2117, codProv: 22, label: "San Carlos S." },
    { value: 2118, codProv: 22, label: "San Cristóbal" },
    { value: 2119, codProv: 22, label: "San Eduardo" },
    { value: 2120, codProv: 22, label: "San Eugenio" },
    { value: 2121, codProv: 22, label: "San Fabián" },
    { value: 2122, codProv: 22, label: "San Fco. de Santa Fé" },
    { value: 2123, codProv: 22, label: "San Genaro" },
    { value: 2124, codProv: 22, label: "San Genaro N." },
    { value: 2125, codProv: 22, label: "San Gregorio" },
    { value: 2126, codProv: 22, label: "San Guillermo" },
    { value: 2127, codProv: 22, label: "San Javier" },
    { value: 2128, codProv: 22, label: "San Jerónimo del Sauce" },
    { value: 2129, codProv: 22, label: "San Jerónimo N." },
    { value: 2130, codProv: 22, label: "San Jerónimo S." },
    { value: 2131, codProv: 22, label: "San Jorge" },
    { value: 2132, codProv: 22, label: "San José de La Esquina" },
    { value: 2133, codProv: 22, label: "San José del Rincón" },
    { value: 2134, codProv: 22, label: "San Justo" },
    { value: 2135, codProv: 22, label: "San Lorenzo" },
    { value: 2136, codProv: 22, label: "San Mariano" },
    { value: 2137, codProv: 22, label: "San Martín de Las Escobas" },
    { value: 2138, codProv: 22, label: "San Martín N." },
    { value: 2139, codProv: 22, label: "San Vicente" },
    { value: 2140, codProv: 22, label: "Sancti Spititu" },
    { value: 2141, codProv: 22, label: "Sanford" },
    { value: 2142, codProv: 22, label: "Santo Domingo" },
    { value: 2143, codProv: 22, label: "Santo Tomé" },
    { value: 2144, codProv: 22, label: "Santurce" },
    { value: 2145, codProv: 22, label: "Sargento Cabral" },
    { value: 2146, codProv: 22, label: "Sarmiento" },
    { value: 2147, codProv: 22, label: "Sastre" },
    { value: 2148, codProv: 22, label: "Sauce Viejo" },
    { value: 2149, codProv: 22, label: "Serodino" },
    { value: 2150, codProv: 22, label: "Silva" },
    { value: 2151, codProv: 22, label: "Soldini" },
    { value: 2152, codProv: 22, label: "Soledad" },
    { value: 2153, codProv: 22, label: "Soutomayor" },
    { value: 2154, codProv: 22, label: "Sta. Clara de Buena Vista" },
    { value: 2155, codProv: 22, label: "Sta. Clara de Saguier" },
    { value: 2156, codProv: 22, label: "Sta. Isabel" },
    { value: 2157, codProv: 22, label: "Sta. Margarita" },
    { value: 2158, codProv: 22, label: "Sta. Maria Centro" },
    { value: 2159, codProv: 22, label: "Sta. María N." },
    { value: 2160, codProv: 22, label: "Sta. Rosa" },
    { value: 2161, codProv: 22, label: "Sta. Teresa" },
    { value: 2162, codProv: 22, label: "Suardi" },
    { value: 2163, codProv: 22, label: "Sunchales" },
    { value: 2164, codProv: 22, label: "Susana" },
    { value: 2165, codProv: 22, label: "Tacuarendí" },
    { value: 2166, codProv: 22, label: "Tacural" },
    { value: 2167, codProv: 22, label: "Tartagal" },
    { value: 2168, codProv: 22, label: "Teodelina" },
    { value: 2169, codProv: 22, label: "Theobald" },
    { value: 2170, codProv: 22, label: "Timbúes" },
    { value: 2171, codProv: 22, label: "Toba" },
    { value: 2172, codProv: 22, label: "Tortugas" },
    { value: 2173, codProv: 22, label: "Tostado" },
    { value: 2174, codProv: 22, label: "Totoras" },
    { value: 2175, codProv: 22, label: "Traill" },
    { value: 2176, codProv: 22, label: "Venado Tuerto" },
    { value: 2177, codProv: 22, label: "Vera" },
    { value: 2178, codProv: 22, label: "Vera y Pintado" },
    { value: 2179, codProv: 22, label: "Vvalueela" },
    { value: 2180, codProv: 22, label: "Vila" },
    { value: 2181, codProv: 22, label: "Villa Amelia" },
    { value: 2182, codProv: 22, label: "Villa Ana" },
    { value: 2183, codProv: 22, label: "Villa Cañas" },
    { value: 2184, codProv: 22, label: "Villa Constitución" },
    { value: 2185, codProv: 22, label: "Villa Eloísa" },
    { value: 2186, codProv: 22, label: "Villa Gdor. Gálvez" },
    { value: 2187, codProv: 22, label: "Villa Guillermina" },
    { value: 2188, codProv: 22, label: "Villa Minetti" },
    { value: 2189, codProv: 22, label: "Villa Mugueta" },
    { value: 2190, codProv: 22, label: "Villa Ocampo" },
    { value: 2191, codProv: 22, label: "Villa San José" },
    { value: 2192, codProv: 22, label: "Villa Saralegui" },
    { value: 2193, codProv: 22, label: "Villa Trinvaluead" },
    { value: 2194, codProv: 22, label: "Villada" },
    { value: 2195, codProv: 22, label: "Virginia" },
    { value: 2196, codProv: 22, label: "Wheelwright" },
    { value: 2197, codProv: 22, label: "Zavalla" },
    { value: 2198, codProv: 22, label: "Zenón Pereira" },
    { value: 2199, codProv: 23, label: "Añatuya" },
    { value: 2200, codProv: 23, label: "Árraga" },
    { value: 2201, codProv: 23, label: "Bandera" },
    { value: 2202, codProv: 23, label: "Bandera Bajada" },
    { value: 2203, codProv: 23, label: "Beltrán" },
    { value: 2204, codProv: 23, label: "Brea Pozo" },
    { value: 2205, codProv: 23, label: "Campo Gallo" },
    { value: 2206, codProv: 23, label: "Capital" },
    { value: 2207, codProv: 23, label: "Chilca Juliana" },
    { value: 2208, codProv: 23, label: "Choya" },
    { value: 2209, codProv: 23, label: "Clodomira" },
    { value: 2210, codProv: 23, label: "Col. Alpina" },
    { value: 2211, codProv: 23, label: "Col. Dora" },
    { value: 2212, codProv: 23, label: "Col. El Simbolar Robles" },
    { value: 2213, codProv: 23, label: "El Bobadal" },
    { value: 2214, codProv: 23, label: "El Charco" },
    { value: 2215, codProv: 23, label: "El Mojón" },
    { value: 2216, codProv: 23, label: "Estación Atamisqui" },
    { value: 2217, codProv: 23, label: "Estación Simbolar" },
    { value: 2218, codProv: 23, label: "Fernández" },
    { value: 2219, codProv: 23, label: "Fortín Inca" },
    { value: 2220, codProv: 23, label: "Frías" },
    { value: 2221, codProv: 23, label: "Garza" },
    { value: 2222, codProv: 23, label: "Gramilla" },
    { value: 2223, codProv: 23, label: "Guardia Escolta" },
    { value: 2224, codProv: 23, label: "Herrera" },
    { value: 2225, codProv: 23, label: "Icaño" },
    { value: 2226, codProv: 23, label: "Ing. Forres" },
    { value: 2227, codProv: 23, label: "La Banda" },
    { value: 2228, codProv: 23, label: "La Cañada" },
    { value: 2229, codProv: 23, label: "Laprvaluea" },
    { value: 2230, codProv: 23, label: "Lavalle" },
    { value: 2231, codProv: 23, label: "Loreto" },
    { value: 2232, codProv: 23, label: "Los Juríes" },
    { value: 2233, codProv: 23, label: "Los Núñez" },
    { value: 2234, codProv: 23, label: "Los Pirpintos" },
    { value: 2235, codProv: 23, label: "Los Quiroga" },
    { value: 2236, codProv: 23, label: "Los Telares" },
    { value: 2237, codProv: 23, label: "Lugones" },
    { value: 2238, codProv: 23, label: "Malbrán" },
    { value: 2239, codProv: 23, label: "Matara" },
    { value: 2240, codProv: 23, label: "Medellín" },
    { value: 2241, codProv: 23, label: "Monte Quemado" },
    { value: 2242, codProv: 23, label: "Nueva Esperanza" },
    { value: 2243, codProv: 23, label: "Nueva Francia" },
    { value: 2244, codProv: 23, label: "Palo Negro" },
    { value: 2245, codProv: 23, label: "Pampa de Los Guanacos" },
    { value: 2246, codProv: 23, label: "Pinto" },
    { value: 2247, codProv: 23, label: "Pozo Hondo" },
    { value: 2248, codProv: 23, label: "Quimilí" },
    { value: 2249, codProv: 23, label: "Real Sayana" },
    { value: 2250, codProv: 23, label: "Sachayoj" },
    { value: 2251, codProv: 23, label: "San Pedro de Guasayán" },
    { value: 2252, codProv: 23, label: "Selva" },
    { value: 2253, codProv: 23, label: "Sol de Julio" },
    { value: 2254, codProv: 23, label: "Sumampa" },
    { value: 2255, codProv: 23, label: "Suncho Corral" },
    { value: 2256, codProv: 23, label: "Taboada" },
    { value: 2257, codProv: 23, label: "Tapso" },
    { value: 2258, codProv: 23, label: "Termas de Rio Hondo" },
    { value: 2259, codProv: 23, label: "Tintina" },
    { value: 2260, codProv: 23, label: "Tomas Young" },
    { value: 2261, codProv: 23, label: "Vilelas" },
    { value: 2262, codProv: 23, label: "Villa Atamisqui" },
    { value: 2263, codProv: 23, label: "Villa La Punta" },
    { value: 2264, codProv: 23, label: "Villa Ojo de Agua" },
    { value: 2265, codProv: 23, label: "Villa Río Hondo" },
    { value: 2266, codProv: 23, label: "Villa Salavina" },
    { value: 2267, codProv: 23, label: "Villa Unión" },
    { value: 2268, codProv: 23, label: "Vilmer" },
    { value: 2269, codProv: 23, label: "Weisburd" },
    { value: 2270, codProv: 24, label: "Río Grande" },
    { value: 2271, codProv: 24, label: "Tolhuin" },
    { value: 2272, codProv: 24, label: "Ushuaia" },
    { value: 2273, codProv: 25, label: "Acheral" },
    { value: 2274, codProv: 25, label: "Agua Dulce" },
    { value: 2275, codProv: 25, label: "Aguilares" },
    { value: 2276, codProv: 25, label: "Alderetes" },
    { value: 2277, codProv: 25, label: "Alpachiri" },
    { value: 2278, codProv: 25, label: "Alto Verde" },
    { value: 2279, codProv: 25, label: "Amaicha del Valle" },
    { value: 2280, codProv: 25, label: "Amberes" },
    { value: 2281, codProv: 25, label: "Ancajuli" },
    { value: 2282, codProv: 25, label: "Arcadia" },
    { value: 2283, codProv: 25, label: "Atahona" },
    { value: 2284, codProv: 25, label: "Banda del Río Sali" },
    { value: 2285, codProv: 25, label: "Bella Vista" },
    { value: 2286, codProv: 25, label: "Buena Vista" },
    { value: 2287, codProv: 25, label: "Burruyacú" },
    { value: 2288, codProv: 25, label: "Capitán Cáceres" },
    { value: 2289, codProv: 25, label: "Cevil Redondo" },
    { value: 2290, codProv: 25, label: "Choromoro" },
    { value: 2291, codProv: 25, label: "Ciudacita" },
    { value: 2292, codProv: 25, label: "Colalao del Valle" },
    { value: 2293, codProv: 25, label: "Colombres" },
    { value: 2294, codProv: 25, label: "Concepción" },
    { value: 2295, codProv: 25, label: "Delfín Gallo" },
    { value: 2296, codProv: 25, label: "El Bracho" },
    { value: 2297, codProv: 25, label: "El Cadillal" },
    { value: 2298, codProv: 25, label: "El Cercado" },
    { value: 2299, codProv: 25, label: "El Chañar" },
    { value: 2300, codProv: 25, label: "El Manantial" },
    { value: 2301, codProv: 25, label: "El Mojón" },
    { value: 2302, codProv: 25, label: "El Mollar" },
    { value: 2303, codProv: 25, label: "El Naranjito" },
    { value: 2304, codProv: 25, label: "El Naranjo" },
    { value: 2305, codProv: 25, label: "El Polear" },
    { value: 2306, codProv: 25, label: "El Puestito" },
    { value: 2307, codProv: 25, label: "El Sacrificio" },
    { value: 2308, codProv: 25, label: "El Timbó" },
    { value: 2309, codProv: 25, label: "Escaba" },
    { value: 2310, codProv: 25, label: "Esquina" },
    { value: 2311, codProv: 25, label: "Estación Aráoz" },
    { value: 2312, codProv: 25, label: "Famaillá" },
    { value: 2313, codProv: 25, label: "Gastone" },
    { value: 2314, codProv: 25, label: "Gdor. Garmendia" },
    { value: 2315, codProv: 25, label: "Gdor. Piedrabuena" },
    { value: 2316, codProv: 25, label: "Graneros" },
    { value: 2317, codProv: 25, label: "Huasa Pampa" },
    { value: 2318, codProv: 25, label: "J. B. Alberdi" },
    { value: 2319, codProv: 25, label: "La Cocha" },
    { value: 2320, codProv: 25, label: "La Esperanza" },
    { value: 2321, codProv: 25, label: "La Florvaluea" },
    { value: 2322, codProv: 25, label: "La Ramada" },
    { value: 2323, codProv: 25, label: "La Trinvaluead" },
    { value: 2324, codProv: 25, label: "Lamadrvalue" },
    { value: 2325, codProv: 25, label: "Las Cejas" },
    { value: 2326, codProv: 25, label: "Las Talas" },
    { value: 2327, codProv: 25, label: "Las Talitas" },
    { value: 2328, codProv: 25, label: "Los Bulacio" },
    { value: 2329, codProv: 25, label: "Los Gómez" },
    { value: 2330, codProv: 25, label: "Los Nogales" },
    { value: 2331, codProv: 25, label: "Los Pereyra" },
    { value: 2332, codProv: 25, label: "Los Pérez" },
    { value: 2333, codProv: 25, label: "Los Puestos" },
    { value: 2334, codProv: 25, label: "Los Ralos" },
    { value: 2335, codProv: 25, label: "Los Sarmientos" },
    { value: 2336, codProv: 25, label: "Los Sosa" },
    { value: 2337, codProv: 25, label: "Lules" },
    { value: 2338, codProv: 25, label: "M. García Fernández" },
    { value: 2339, codProv: 25, label: "Manuela Pedraza" },
    { value: 2340, codProv: 25, label: "Medinas" },
    { value: 2341, codProv: 25, label: "Monte Bello" },
    { value: 2342, codProv: 25, label: "Monteagudo" },
    { value: 2343, codProv: 25, label: "Monteros" },
    { value: 2344, codProv: 25, label: "Padre Monti" },
    { value: 2345, codProv: 25, label: "Pampa Mayo" },
    { value: 2346, codProv: 25, label: "Quilmes" },
    { value: 2347, codProv: 25, label: "Raco" },
    { value: 2348, codProv: 25, label: "Ranchillos" },
    { value: 2349, codProv: 25, label: "Río Chico" },
    { value: 2350, codProv: 25, label: "Río Colorado" },
    { value: 2351, codProv: 25, label: "Río Seco" },
    { value: 2352, codProv: 25, label: "Rumi Punco" },
    { value: 2353, codProv: 25, label: "San Andrés" },
    { value: 2354, codProv: 25, label: "San Felipe" },
    { value: 2355, codProv: 25, label: "San Ignacio" },
    { value: 2356, codProv: 25, label: "San Javier" },
    { value: 2357, codProv: 25, label: "San José" },
    { value: 2358, codProv: 25, label: "San Miguel de 25" },
    { value: 2359, codProv: 25, label: "San Pedro" },
    { value: 2360, codProv: 25, label: "San Pedro de Colalao" },
    { value: 2361, codProv: 25, label: "Santa Rosa de Leales" },
    { value: 2362, codProv: 25, label: "Sgto. Moya" },
    { value: 2363, codProv: 25, label: "Siete de Abril" },
    { value: 2364, codProv: 25, label: "Simoca" },
    { value: 2365, codProv: 25, label: "Soldado Maldonado" },
    { value: 2366, codProv: 25, label: "Sta. Ana" },
    { value: 2367, codProv: 25, label: "Sta. Cruz" },
    { value: 2368, codProv: 25, label: "Sta. Lucía" },
    { value: 2369, codProv: 25, label: "Taco Ralo" },
    { value: 2370, codProv: 25, label: "Tafí del Valle" },
    { value: 2371, codProv: 25, label: "Tafí Viejo" },
    { value: 2372, codProv: 25, label: "Tapia" },
    { value: 2373, codProv: 25, label: "Teniente Berdina" },
    { value: 2374, codProv: 25, label: "Trancas" },
    { value: 2375, codProv: 25, label: "Villa Belgrano" },
    { value: 2376, codProv: 25, label: "Villa Benjamín Araoz" },
    { value: 2377, codProv: 25, label: "Villa Chiligasta" },
    { value: 2378, codProv: 25, label: "Villa de Leales" },
    { value: 2379, codProv: 25, label: "Villa Quinteros" },
    { value: 2380, codProv: 25, label: "Yánima" },
    { value: 2381, codProv: 25, label: "Yerba Buena" },
    { value: 2382, codProv: 25, label: "Yerba Buena (S)" }
]