import React from 'react'
import ListTexts from '../organisms/ListTexts'
import { POLITICAS_Y_PROTECCION } from '../../constants/politicas_de_conf_y_proteccion_datos'

const PoliticaYProteccionTemplate = () => {
  return (
    <>
      <ListTexts array={POLITICAS_Y_PROTECCION}/>
    </>
  )
}

export default PoliticaYProteccionTemplate
