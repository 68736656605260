import { LIST_TOWNS } from "./constants/provinces_and_towns";

export const buscarProvinciaEnLocalidades = (idProvincia)=> {
    if(isNaN(idProvincia)) return [];
    return LIST_TOWNS.filter(localidad => localidad.codProv === idProvincia);
}

export const getDevice = ()=>{
    let device = 'Desktop';
    if (window.innerWidth < 768) {
        device = 'Mobile';
    }
    return device;
}

export const convertObjToString = (obj) => {
    let fields = '{';
    for (const key in obj) {
        const newKey = `\"${key}\"`;
        fields += `${newKey}:"${obj[key]}",`;
    }
    fields = fields.slice(0, -1) + '}';
    return JSON.stringify(fields);
}

