// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (min-width:670px) {
    .image-home{
        width: 100%;
    }
}

.image-home{
    position: relative;
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/HomeScreen.css"],"names":[],"mappings":"AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,2BAA2B;AAC/B","sourcesContent":["@import url(\"vars.css\");\r\n\r\n@media screen and (min-width:670px) {\r\n    .image-home{\r\n        width: 100%;\r\n    }\r\n}\r\n\r\n.image-home{\r\n    position: relative;\r\n    width: 100vw;\r\n    left: 50%;\r\n    transform: translateX(-50%);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
