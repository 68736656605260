import React from 'react';
import logo from "../../assets/Sudamericana_Logo_01_Color.png"
import "../../assets/styles/Header.css"
import { PUBLIC_ROUTES } from '../../routes/public.routes';

function ResponsiveAppBar() {
  return (
    <header className='container-header'>
      <div className='head-content' onClick={()=>window.location.href=PUBLIC_ROUTES.Home}>
        <img src={logo} className='logo-imagen' />
      </div>
    </header>
  );
}
export default ResponsiveAppBar;