import React from 'react'
import ItemList from './Item'
import "../../assets/styles/ListText.css"

const ListTexts = (props)=> {
    if (!props.array) {
      return null; // O manejarlo de otra manera según tu lógica
    }
    return (
      <main className='container-main'>
        {props.array.map((element,index) => <ItemList element={element} key={index} />)}
      </main>
    );
  };
  

export default ListTexts
