import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Input from '../atoms/Input';
import Select from '../atoms/Select';
import { LIST_PROVINCES } from '../../constants/provinces_and_towns';
import { ES_CLIENTE } from '../../constants/list_es_cliente';
import ButtonIcon from '../atoms/Button';
import { buscarProvinciaEnLocalidades } from '../../utils';
import { PUBLIC_ROUTES } from '../../routes/public.routes';

const FormLibroQueja = ({ handleChange, valueForm, handleBlur }) => {
    const [localidades, setlocalidades] = useState([]);

    useEffect(() => {
        setlocalidades(buscarProvinciaEnLocalidades(valueForm.Provincias))
    }, [valueForm.Provincias]);
    
    return (
        <Grid container spacing={2} maxWidth={"md"} sx={{ marginBottom: "2rem" }}>
            <Grid item xs={12} sm={12} md={6}>
                <Input restricciones={["alfanumerico"]} name="_Cu_l_es_tu_Nombre_" placeholder="Nombre" onChange={handleChange} value={valueForm?._Cu_l_es_tu_Nombre_} onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Input restricciones={["alfanumerico"]} name="Apellido" placeholder="Apellido" onChange={handleChange} value={valueForm?.Apellido} onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Input restricciones={["soloTelefono"]} name="Teléfono" placeholder="Teléfono" onChange={handleChange} value={valueForm?.Teléfono} onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Input restricciones={["email"]} name="Email" placeholder="Email" onChange={handleChange} value={valueForm?.Email} onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Select value={valueForm.Provincias} name="Provincias" options={LIST_PROVINCES} onChange={handleChange} type="text" required onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Select value={valueForm?.Localidades} name="Localidades" options={localidades} onChange={handleChange} type="text" required disabled={!valueForm.Provincias} onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Select value={valueForm?._Sos_Cliente_Type} name="_Sos_Cliente_Type" defaultValue={ES_CLIENTE.Default} onBlur={handleBlur} options={[{ label: ES_CLIENTE.Default, value: ES_CLIENTE.Default }, { label: ES_CLIENTE.Si, value: ES_CLIENTE.Si }, { label: ES_CLIENTE.No, value: ES_CLIENTE.No }]} onChange={handleChange} type="text" required />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
                <Input restricciones={["soloNumerosDecimales"]} name="_Sos_Cliente_TypeInput" placeholder="Número de póliza" onChange={handleChange} value={valueForm?._Sos_Cliente_TypeInput} disabled={valueForm._Sos_Cliente_Type !== ES_CLIENTE.Si} onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Input type="text" label="Dejanos tu mensaje" variant="standard" value={valueForm?.Dejanos_tu_mensaje} onChange={handleChange} restricciones={["alfanumerico"]} name="Dejanos_tu_mensaje" onBlur={handleBlur} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <ButtonIcon variant="outlined" size="large" to={PUBLIC_ROUTES.GraciasLibroQuejas} className="btn-send">Enviar</ButtonIcon>
            </Grid>
        </Grid>
    )
}

export default FormLibroQueja
