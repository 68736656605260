// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-header{
    background-color: white;
    height: 9vh;
    margin-bottom: 1rem;
}

.head-content{
    padding: 30px 0px 0px 30px;
}

.logo-imagen{
    pointer-events: auto;
    cursor: pointer;
    max-width: 260px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Header.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".container-header{\r\n    background-color: white;\r\n    height: 9vh;\r\n    margin-bottom: 1rem;\r\n}\r\n\r\n.head-content{\r\n    padding: 30px 0px 0px 30px;\r\n}\r\n\r\n.logo-imagen{\r\n    pointer-events: auto;\r\n    cursor: pointer;\r\n    max-width: 260px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
