// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container{
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-title-home);
    padding: 0;
    font-family: var(--font-family-text);
    font-size: var(--font-size-title);
    margin: 0 0 20px 0;
    font-weight: var(--font-weight);
    color: var( --color-sura);
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Title.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,8BAA8B;IAC9B,UAAU;IACV,oCAAoC;IACpC,iCAAiC;IACjC,kBAAkB;IAClB,+BAA+B;IAC/B,yBAAyB;IACzB,kBAAkB;AACtB","sourcesContent":["@import url(\"./vars.css\");\r\n\r\n.title-container{\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    color: var(--color-title-home);\r\n    padding: 0;\r\n    font-family: var(--font-family-text);\r\n    font-size: var(--font-size-title);\r\n    margin: 0 0 20px 0;\r\n    font-weight: var(--font-weight);\r\n    color: var( --color-sura);\r\n    text-align: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
