// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a{
    text-decoration: none;
}
button.MuiButton-root.ButtonIcon {
    min-width: 0;
    padding: 6px 8px;
}
button.MuiButton-root.ButtonIcon.hasChildrenIconStart,
button.MuiButton-root.ButtonIcon.hasChildrenIconEnd{
    padding: 3px 8px;
}
button.MuiButton-root.ButtonIcon span.MuiButton-startIcon,
button.MuiButton-root.ButtonIcon span.MuiButton-endIcon{
    margin: 0;
}
button.MuiButton-root.ButtonIcon.hasChildrenIconStart span.MuiButton-startIcon {
    margin-right: 4px;
}
button.MuiButton-root.ButtonIcon.hasChildrenIconEnd span.MuiButton-endIcon {
    margin-left: 4px;
}

button.MuiButton-root.ButtonIcon{
    width: 150px;
    border-radius: 8px;
    padding: 6px 30px;
    font-size: 1.2rem;
    font-size: 21px;
    box-shadow: none;
    transition: 0.3s;
}

.ButtonIcon{
    background-color: var(--color-sura) !important;
    color: white !important;
}

.ButtonIcon:hover{
    background-color: rgba(0, 51, 160, 0.7) !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Button.css"],"names":[],"mappings":"AAEA;IACI,qBAAqB;AACzB;AACA;IACI,YAAY;IACZ,gBAAgB;AACpB;AACA;;IAEI,gBAAgB;AACpB;AACA;;IAEI,SAAS;AACb;AACA;IACI,iBAAiB;AACrB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,uBAAuB;AAC3B;;AAEA;IACI,kDAAkD;AACtD","sourcesContent":["@import url(\"./vars.css\");\r\n\r\na{\r\n    text-decoration: none;\r\n}\r\nbutton.MuiButton-root.ButtonIcon {\r\n    min-width: 0;\r\n    padding: 6px 8px;\r\n}\r\nbutton.MuiButton-root.ButtonIcon.hasChildrenIconStart,\r\nbutton.MuiButton-root.ButtonIcon.hasChildrenIconEnd{\r\n    padding: 3px 8px;\r\n}\r\nbutton.MuiButton-root.ButtonIcon span.MuiButton-startIcon,\r\nbutton.MuiButton-root.ButtonIcon span.MuiButton-endIcon{\r\n    margin: 0;\r\n}\r\nbutton.MuiButton-root.ButtonIcon.hasChildrenIconStart span.MuiButton-startIcon {\r\n    margin-right: 4px;\r\n}\r\nbutton.MuiButton-root.ButtonIcon.hasChildrenIconEnd span.MuiButton-endIcon {\r\n    margin-left: 4px;\r\n}\r\n\r\nbutton.MuiButton-root.ButtonIcon{\r\n    width: 150px;\r\n    border-radius: 8px;\r\n    padding: 6px 30px;\r\n    font-size: 1.2rem;\r\n    font-size: 21px;\r\n    box-shadow: none;\r\n    transition: 0.3s;\r\n}\r\n\r\n.ButtonIcon{\r\n    background-color: var(--color-sura) !important;\r\n    color: white !important;\r\n}\r\n\r\n.ButtonIcon:hover{\r\n    background-color: rgba(0, 51, 160, 0.7) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
