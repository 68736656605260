import React from 'react'
import "../../assets/styles/Article.css"

const Article = ({title="",children, styles}) => {
    return (
        <article className='container-article' style={styles}>
            <header className='title-terminos-uso'><strong>{title}</strong></header>
            <div>{children}</div>
        </article>
    )
}

export default Article