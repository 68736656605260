import React from 'react'
import Article from '../molecules/Article'
import "../../assets/styles/Item.css"

const ItemList = ({element}) => {
    return (
        <Article title={element.title} key={element.index}>
            <span className={"text-terminos-uso"}>{element.content } </span>
        </Article>
    )
}

export default ItemList
