
import { PUBLIC_ROUTES } from "./public.routes";
import HomeScreen from "../pages/HomeScreen";
import PoliticasYProteccion from "../pages/PoliticasYProteccion";
import TerminosYCondiciones from "../pages/TerminosYCondiciones";
import LibroQuejas from "../pages/LibroQuejas";
import GraciasLibroQuejas from "../pages/GraciasLibroQuejas";

export const ROUTES = [
    { path: PUBLIC_ROUTES.Home, component: HomeScreen },
    { path: PUBLIC_ROUTES.LibroDeQuejas, component: LibroQuejas },
    { path: PUBLIC_ROUTES.TerminosYCondiciones, component: TerminosYCondiciones },
    { path: PUBLIC_ROUTES.PoliticasDeProteccion, component: PoliticasYProteccion },
    { path: PUBLIC_ROUTES.GraciasLibroQuejas, component: GraciasLibroQuejas }

]