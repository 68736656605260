// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-terminos-uso2{
    font-weight: var(--font-weight-upper);
    line-height: var(--line-height);
    font-size: var( --font-size-medium)*1.1;
    color: var(--color-sura);
    text-justify: auto !important;
}

.text-terminos-uso2{
    font-weight: var(--font-weight);
    font-size: var( --font-size-medium)*1.1;
    color: var(--color-subtitle-grey);
    line-height: var(--line-height);
}   

.container-main{
    width: 70vw;
    margin-top: 40px;
    background-color: #fff ;
    padding: 0% 8% 0% 8%;
    margin-bottom: 4vh;
}

.container-mainfooter-terminos{
    width: 70vw;
    background-color: #fff ;
    padding: 0% 8% 0% 8%;
    margin: auto;
}

@media screen and (max-width:768px) {
    .container-main{
        width: 100vw;
        margin-top: 40px;

    }  

    .container-mainfooter-terminos{
        width: 100vw;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ContainerFooterTerminosCondiones.css"],"names":[],"mappings":"AAEA;IACI,qCAAqC;IACrC,+BAA+B;IAC/B,uCAAuC;IACvC,wBAAwB;IACxB,6BAA6B;AACjC;;AAEA;IACI,+BAA+B;IAC/B,uCAAuC;IACvC,iCAAiC;IACjC,+BAA+B;AACnC;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI;QACI,YAAY;QACZ,gBAAgB;;IAEpB;;IAEA;QACI,YAAY;IAChB;;AAEJ","sourcesContent":["@import url(\"./vars.css\");\r\n\r\n.title-terminos-uso2{\r\n    font-weight: var(--font-weight-upper);\r\n    line-height: var(--line-height);\r\n    font-size: var( --font-size-medium)*1.1;\r\n    color: var(--color-sura);\r\n    text-justify: auto !important;\r\n}\r\n\r\n.text-terminos-uso2{\r\n    font-weight: var(--font-weight);\r\n    font-size: var( --font-size-medium)*1.1;\r\n    color: var(--color-subtitle-grey);\r\n    line-height: var(--line-height);\r\n}   \r\n\r\n.container-main{\r\n    width: 70vw;\r\n    margin-top: 40px;\r\n    background-color: #fff ;\r\n    padding: 0% 8% 0% 8%;\r\n    margin-bottom: 4vh;\r\n}\r\n\r\n.container-mainfooter-terminos{\r\n    width: 70vw;\r\n    background-color: #fff ;\r\n    padding: 0% 8% 0% 8%;\r\n    margin: auto;\r\n}\r\n\r\n@media screen and (max-width:768px) {\r\n    .container-main{\r\n        width: 100vw;\r\n        margin-top: 40px;\r\n\r\n    }  \r\n\r\n    .container-mainfooter-terminos{\r\n        width: 100vw;\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
