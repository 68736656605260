export const CONTENT_TERMINOS_Y_CONDICIONES = [
    {
        title: "TÉRMINOS LEGALES Y CONDICIONES DE USO",
        content: "Lea detenidamente los siguientes términos legales y condiciones de uso. Mediante la utilización de la página https://www.sudamericanaseguros.com.ar y los servicios digitales de Sudamericana Seguros, en adelante la “Compañía”, usted presta conformidad y queda sujeto a todos los términos, condiciones, y avisos que puedan encontrarse en estos Términos Legales y Condiciones de Uso."
    }, {
        title: "CORREO ELECTRÓNICO",
        content: "Los mensajes de correo electrónico habituales enviados por Internet pueden ser objeto de posibles interferencias, pérdidas o alteraciones. Sudamericana Seguros no asume responsabilidad alguna por ello, ni tampoco ante usted u otra persona por daños o problemas de otra índole, en relación con mensajes de correo electrónico que usted nos envíe."
    }, {
        title: "PROPIEDAD INTELECTUAL. USO INDEBIDO",
        content: "La información, las imágenes gráficas, el diseño, los isotipos, isologotipos o logotipos, las marcas registradas, la publicidad y el texto, así como los productos ofrecidos incluidos en los Sitios y los servicios digitales son de propiedad exclusiva de la Compañía. Por lo tanto, no pueden ser modificados, copiados, reproducidos o distribuidos, transmitidos, divulgados, cedidos total o parcialmente, ni se encuentra autorizada la creación de trabajos derivados del uso de la información provista por el sitio y servicios digitales de Sudamericana Seguros sin el previo consentimiento por escrito de la Compañía. En ocasión de hacer uso indebido de los sitios y servicios digitales de la Compañía, el usuario asume la responsabilidad por toda transmisión ilícita de información o de carácter injurioso, ofensivo, difamatorio y/u objetable, que en sí mismos constituyan conductas contrarias a la legislación vigente nacional den lugar a acciones por responsabilidad civil."
    }, {
        title: "JURISDICCIÓN Y LEY APLICABLE",
        content: "Sudamericana Seguros manifiesta que ante controversias y/o conflicto de intereses que pueda surgir como consecuencia del uso del sito será resuelto de acuerdo con la legislación vigente en la República Argentina sometiéndose a la jurisdicción y competencia de los Tribunales Ordinarios de la Ciudad de Buenos Aires renunciando expresamente a todo otro fuero o jurisdicción que pudiere corresponder."
    },
    {
        title: "SOBRE LOS TÉRMINOS Y CONDICIONES DEL SITIO",
        content: "Nos reservamos el derecho de modificar los Términos Legales y Condiciones de Uso en cualquier momento y sin previo aviso Las modificaciones entrarán en vigencia a partir del momento en que sean publicadas en este Sitio, por lo que se aconseja la lectura de los Términos y Condiciones cada vez que ingrese en el sitio, a fin de conocer dichas modificaciones. Los productos y servicios sobre los que se informan en los Sitios y servicios digitales están sujetos a los términos y condiciones del contrato correspondiente que rige su utilización. En caso de que alguna parte de los términos legales y condiciones de uso sea considerada nula, ilegal o que no sea exigible judicialmente por algún tribunal u otro órgano competente, dicha parte será separada del resto de los términos y condiciones, los cuales seguirán teniendo validez y serán exigibles judicialmente hasta el máximo permitido por ley."
    }, {
        title: "VÍNCULOS CON CONTENIDOS O SITIOS DE TERCEROS",
        content: "Los Sitios y servicios digitales pueden brindar acceso a la información suministrada por terceros o vínculos de hipertexto con otras direcciones de Internet.  La información contenida en nuestros Sitios y servicios digitales de ninguna manera manifiesta ya sea expresa o implícitamente, la adhesión o aprobación del asesoramiento, las opiniones, la información, los productos o servicios de terceros. No controlamos, ni garantizamos, ni asumimos responsabilidad alguna por la exactitud, oportunidad, o incluso la disponibilidad continua o existencia de dichos contenidos, hipervínculos, sitios o páginas de terceros vinculados con los Sitios y servicios digitales de la Compañía. El acceso a los vínculos de cualquier otro sitio o página quedará bajo su exclusiva responsabilidad y no asumiremos responsabilidad alguna por los daños que pueda causar dicha vinculación. Se proporcionan vínculos con sitios de software para descargar sólo para su comodidad, y no asumiremos ninguna responsabilidad por las dificultades o consecuencias asociadas a la descarga de software. El uso de software para descargar está regido por los términos legales del contrato de licencia, si existiere, que acompaña al software o se suministra con él."
    }, {
        title: "SERVICIO DE ATENCIÓN AL ASEGURADO",
        content: "Sudamericana Seguros dispone de un Servicio de Atención al Asegurado que atenderá las consultas y reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y / o derechohabientes. El Servicio de Atención al Asegurado está por:"
    }
]