// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-main{
    width: 70vw;
    margin-top: 40px;
    margin: auto;
    background-color: #fff ;
    padding: 0% 8% 0% 8%;
    margin-bottom: 4vh;
}

@media screen and (max-width:768px) {
    .container-main{
        width: 100vw;
    }  
    
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ListText.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;IACvB,oBAAoB;IACpB,kBAAkB;AACtB;;AAEA;IACI;QACI,YAAY;IAChB;;AAEJ","sourcesContent":[".container-main{\r\n    width: 70vw;\r\n    margin-top: 40px;\r\n    margin: auto;\r\n    background-color: #fff ;\r\n    padding: 0% 8% 0% 8%;\r\n    margin-bottom: 4vh;\r\n}\r\n\r\n@media screen and (max-width:768px) {\r\n    .container-main{\r\n        width: 100vw;\r\n    }  \r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
