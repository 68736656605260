// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-terminos-uso{
    font-weight: var(--font-weight-upper);
    line-height: var(--line-height);
    font-size: var( --font-size-medium)*1.1;
    color: var(--color-sura);
    margin-bottom: 5px;
}

.container-article{
    padding: 8px 0px;
    text-align: justify;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Article.css"],"names":[],"mappings":"AAEA;IACI,qCAAqC;IACrC,+BAA+B;IAC/B,uCAAuC;IACvC,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;AACf","sourcesContent":["@import url(\"./vars.css\");\r\n\r\n.title-terminos-uso{\r\n    font-weight: var(--font-weight-upper);\r\n    line-height: var(--line-height);\r\n    font-size: var( --font-size-medium)*1.1;\r\n    color: var(--color-sura);\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.container-article{\r\n    padding: 8px 0px;\r\n    text-align: justify;\r\n    width: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
