import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ROUTES } from './routes/models.routes';
import { HeaderVista } from './components';
import Footer from './components/molecules/Footer';
import './App.css'


function App() {
  return (
    <div className='app-container'>
      <HeaderVista />
      <BrowserRouter>
        <Routes>
          {ROUTES.map((route, index) => (
            <Route key={index} path={route.path} element={<route.component />} />
          ))}
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
