import React from 'react'
import "../../assets/styles/Title.css"

const Title = ({title}) => {
    return (
        <header>
            <h1 className="title-container">{title}</h1>
        </header>
    )
}

export default Title
