const API_GALICIA = process.env.REACT_APP_API_GALICIA

const headers = new Headers();
headers.append('Accept', 'application/json');
headers.append('Authorization', 'Bearer tu-token-aqui');

export const saveDataForm = async ({ data, id }) => {
    try {
        const response = await fetch(`${API_GALICIA}"/api/formularios/tracking?${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*', 
                'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            return { error: 'Network response was not ok' };
        }
        return await response.json();
    } catch (e) {
        return { error: 'Network response was not ok' };
    }
}


export const submitForm = async ({ data, id }) => {
    try {
        const response = await fetch(`${API_GALICIA}"/api/formulariosRespuestas/createFormularioRespuesta${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) {
            return { error: 'Network response was not ok' };
        }
        return await response.json();
    } catch (error) {
        return { error: 'Network response was not ok' };
    }
}