import React from 'react'
import ListTexts from '../organisms/ListTexts'
import { CONTENT_TERMINOS_Y_CONDICIONES } from '../../constants/terminos-y-condiciones'
import "../../assets/styles/ContainerFooterTerminosCondiones.css"

const TerminoYCondiciones = () => {
  return (
    <main style={{margin:"px 0px"}}>
      <ListTexts array={CONTENT_TERMINOS_Y_CONDICIONES} />
      <div className="container-mainfooter-terminos">
        <span className="text-terminos-uso2">RESPONSABLE: Daniel Romualdo Schmisser</span><br/>
        <span className="text-terminos-uso2">Tel.011 4114-8000 int. 8138 | daniel.schmisser@sudamericanaseguros.com.ar</span><br/>
        <span className="text-terminos-uso2">Suplente, Braulio Gomez</span><br/>
        <span className="text-terminos-uso2">Tel. 011 4114-8000 int 8165 | braulio.gomez@sudamericanaseguros.com.ar</span>
      </div>
    </main>
  )
}

export default TerminoYCondiciones
