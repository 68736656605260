import React from 'react'
import PoliticaYProteccionTemplate from '../components/templates/PoliticaYProteccionTemplate'

const PoliticasYProteccion = () => {
  return (
    <PoliticaYProteccionTemplate/>
  )
}

export default PoliticasYProteccion
