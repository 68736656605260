// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* @import "../fonts/OpenSans.ttf" */

.MuiFormLabel-root-MuiInputLabel-root,
.MuiSelect-select.MuiOutlinedInput-input {
  font-family: "Open Sans", sans-serif;
  text-align: left;
}

.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: #eaeaea;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {
  color: rgba(211, 211, 211, 0);
}

.MuiFormHelperText-root.MuiFormHelperText-sizeMedium {
  font-family: "Open Sans", sans-serif;
}

.MuiInputLabel-root.MuiInputLabel-outlined {
  font-family: "Open Sans", sans-serif;
  color: #545454;
}

.css-111avd1-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
  background-color: #eaeaea;
}

#menu-
  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-height: 500px;
  min-height: 50px;
}

.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: var(--border-radius) !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Input.css"],"names":[],"mappings":"AAAA,oCAAoC;;AAEpC;;EAEE,oCAAoC;EACpC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;EACpC,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,8CAA8C;AAChD","sourcesContent":["/* @import \"../fonts/OpenSans.ttf\" */\r\n\r\n.MuiFormLabel-root-MuiInputLabel-root,\r\n.MuiSelect-select.MuiOutlinedInput-input {\r\n  font-family: \"Open Sans\", sans-serif;\r\n  text-align: left;\r\n}\r\n\r\n.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {\r\n  background-color: #eaeaea;\r\n}\r\n\r\n.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-disabled {\r\n  color: rgba(211, 211, 211, 0);\r\n}\r\n\r\n.MuiFormHelperText-root.MuiFormHelperText-sizeMedium {\r\n  font-family: \"Open Sans\", sans-serif;\r\n}\r\n\r\n.MuiInputLabel-root.MuiInputLabel-outlined {\r\n  font-family: \"Open Sans\", sans-serif;\r\n  color: #545454;\r\n}\r\n\r\n.css-111avd1-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {\r\n  background-color: #eaeaea;\r\n}\r\n\r\n#menu-\r\n  > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {\r\n  max-height: 500px;\r\n  min-height: 50px;\r\n}\r\n\r\n.css-8j6b76-MuiInputBase-root-MuiOutlinedInput-root {\r\n  border-radius: var(--border-radius) !important;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
