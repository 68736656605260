import React from 'react';
import logoSsn from '../../assets/SSN-logo-ult.webp';
import '../../assets/styles/Footer.css';
import { PUBLIC_ROUTES } from '../../routes/public.routes';
import { LINKS } from '../../constants/external_links';

const Footer = () => {
  return (
    <footer className='container-footer'>
      <div className="container_links_legales">
        <div className='container-lista'>
          <li className='lista-links'>
            <ul className='link-item'>
              <a href={PUBLIC_ROUTES.LibroDeQuejas} className='text-footer' >Libro de quejas</a>
            </ul>
            <ul className='link-item'>
              <a href={LINKS.WHATSAPP_LINK} target='_blank' className='text-footer'>¿Querés dar de baja tu servicio? Contactanos</a>
            </ul>
            <ul className='link-item'>
              <a href={PUBLIC_ROUTES.TerminosYCondiciones} className='text-footer'>Términos y condiciones</a>
            </ul>
            <ul className='link-item'>
              <a href={PUBLIC_ROUTES.PoliticasDeProteccion} className='text-footer'>Políticas de Privacidad y protección de datos personales</a>
            </ul>
            <ul className='link-item'>
              <a href={LINKS.RECLAMOS_TERCEROS} className='text-footer'>Reclamo de terceros</a>
            </ul>
          </li>
        </div>
      </div>
      <hr style={{width: "90%"}}/>
      <div className="footer_politicas_2" >
        <div className="politica">
          <span className='text-footer' >N° de inscripción SSN: 0025</span>
        </div>
        <div className="politicas">
          <div className='container-departamento-asistencia'>
            <span className='text-footer'>Departamento de Orientación y Asistencia al Asegurado</span>
          </div>
          <div>
            <span className='text-footer' style={{width:"25%"}}>0800-666-8400</span>
          </div>
        </div>
        <div className="politicas">
          <a href="https://www.argentina.gob.ar/ssn" className="text-footer" target="_blank">www.argentina.gob.ar/ssn</a>
        </div>
        <div className='container_imagen_ssn'>
          <img className='imagen_SSN' src={logoSsn} alt='Logo SSN' />
        </div>
      </div>
      <div className='container-legales'>
        <p className='text-footer'>
          La compañía de seguros dispone de un Servicio de Atención al Asegurado que atenderá las consultas y reclamos que presenten los tomadores de seguros, asegurados, beneficiarios y/o derecho habientes.
          En caso de que el reclamo no haya sido resuelto o que haya sido denegada su admisión o desestimado, total o parcialmente, podrá comunicarse con la Superintendencia de Seguros de la Nación por teléfono al 0800 666 8400, correo electrónico a consultas@ssn.gob.ar o formulario disponible en la página argentina.gob.ar/ssn.
          <br /><br/>El Servicio de Atención al Asegurado está integrado por:<br /><br />
          RESPONSABLE: Daniel Romualdo Schmisser – Tel. 011 4114-8000 int. 8138 <br/>Email: daniel.schmisser@sudamericanaseguros.com.ar<br /><br/>
          SUPLENTE: Braulio Gomez – Tel. 011 4114-8000 int 8165 <br/> Email: braulio.gomez@sudamericanaseguros.com.ar<br />
        </p>
      </div>
    </footer>
  );
}

export default Footer;
