import React from 'react'
import react from '../../assets/background-image3.png'
import AboutUs from '../molecules/About'
import Title from "../atoms/Title" 
import "../../assets/styles/HomeScreen.css"

const HomePage = () => {
    return (
        <main className='container-main'>
            <article>
                <div>
                    <img src={react} className="image-home" />
                    <AboutUs />
                </div>
            </article>
        </main>
    )
}

export default HomePage
