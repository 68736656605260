import React, { useState } from 'react'
import {ES_CLIENTE} from "../../constants/list_es_cliente"
import { Container } from '@mui/material';
import Title from '../atoms/Title';
import FormLibroQueja from "../organisms/FormLibroQueja"
import { convertObjToString, getDevice } from '../../utils';
import {saveDataForm} from "../../services/forms"
const API_GALICIA = process.env.REACT_APP_API_GALICIA
const URL_FORM = process.env.REACT_APP_URL_FORM
const ID_FORM = process.env.REACT_APP_ID_FORM

const LibroQuejasPage = () => {
    const [valueForm, setValueForm] = useState({
        _Cu_l_es_tu_Nombre_: '',
        Apellido: '',
        Teléfono: '',
        Email: '',
        Provincias: 0,
        Localidades: 0,
        _Sos_Cliente_Type: ES_CLIENTE.Default,
        _Sos_Cliente_TypeInput: "",
        num_poliza: '',
        Dejanos_tu_mensaje: ''
    });

    const [firstCall, setfirstCall] = useState(true)
    const handleBlurForm = async (firstCall) => {
        let data = "";
        const URL_API = `${API_GALICIA}${URL_FORM}`
        if (firstCall) {
            setfirstCall(false);
            let create_form = {
                id: ID_FORM,
                device: getDevice(),
                url: URL_API
            };
            data = convertObjToString(create_form);
        }
        data = convertObjToString({ ...valueForm, id: ID_FORM });
        await saveDataForm({ data, id: ID_FORM });
    };

    const handleChange = (key, value) => {
        setValueForm({
            ...valueForm,
            [key]: value
        });
    }

    return (
        <Container sx={{ display: "flex", flexDirection: "column", alignItems: "center",marginTop:"40px" }}>
            <Title title={"Dejanos tu comentario y nos pondremos en contacto"} />
            <FormLibroQueja handleChange={handleChange} handleBlur={handleBlurForm} valueForm={valueForm} />
        </Container>
    )
}

export default LibroQuejasPage
