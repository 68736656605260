import React, { useRef } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";

import "../../assets/styles/Button.css";

export default function ButtonIcon(props) {
  const inputRef = useRef(null);

  return (
    <Link to={props.to && !props.disabled ? props.to : "#"} style={props.style}>
      <Button
        className={
          "ButtonIcon " +
          (props.children && props.startIcon ? " hasChildrenIconStart " : "") +
          (props.children && props.endIcon ? " hasChildrenIconEnd " : "")
        }
        variant={props.variant}
        size={props.size}
        color={props.color}
        aria-label={props.arialLabel}
        startIcon={props.startIcon ? props.startIcon : ""}
        endIcon={props.endIcon ? props.endIcon : ""}
        style={props.styleButton && props.styleButton}
        disabled={props.disabled}
      >
        <span>{props.children}</span>
      </Button>
    </Link>
  );
}
