// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./vars.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-terminos-uso{
    font-weight: var(--font-weight);
    font-size: var( --font-size-medium)*1.1;
    color: var(--color-subtitle-grey);
    line-height: var(--line-height);
    text-align: justify;
}   `, "",{"version":3,"sources":["webpack://./src/assets/styles/Item.css"],"names":[],"mappings":"AAEA;IACI,+BAA+B;IAC/B,uCAAuC;IACvC,iCAAiC;IACjC,+BAA+B;IAC/B,mBAAmB;AACvB","sourcesContent":["@import url(\"vars.css\");\r\n\r\n.text-terminos-uso{\r\n    font-weight: var(--font-weight);\r\n    font-size: var( --font-size-medium)*1.1;\r\n    color: var(--color-subtitle-grey);\r\n    line-height: var(--line-height);\r\n    text-align: justify;\r\n}   "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
