// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --color-sura:#0033A0;
    --color-title-black: #80808;
    --color-pink: #d94695;
    --color-subtitle-grey: #808080;
    --font-family-title: Arial;
    --color-title-home: #464646;
    --font-weight-upper: 700;
    --font-weight:500;
    --font-size-small: 12px;
    --font-size-medium: 21px;
    --font-size-big: 34px;
    --font-size-title: 36px;;
    --font-weight: 1.1;
    --background-color-footer: #404040;
    --font-family-text: Arial;
    --margin-top-medium: 20px;
    --border-radius: 16px;
    --line-height: 1.4;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/vars.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,2BAA2B;IAC3B,qBAAqB;IACrB,8BAA8B;IAC9B,0BAA0B;IAC1B,2BAA2B;IAC3B,wBAAwB;IACxB,iBAAiB;IACjB,uBAAuB;IACvB,wBAAwB;IACxB,qBAAqB;IACrB,uBAAuB;IACvB,kBAAkB;IAClB,kCAAkC;IAClC,yBAAyB;IACzB,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;AACtB","sourcesContent":[":root {\r\n    --color-sura:#0033A0;\r\n    --color-title-black: #80808;\r\n    --color-pink: #d94695;\r\n    --color-subtitle-grey: #808080;\r\n    --font-family-title: Arial;\r\n    --color-title-home: #464646;\r\n    --font-weight-upper: 700;\r\n    --font-weight:500;\r\n    --font-size-small: 12px;\r\n    --font-size-medium: 21px;\r\n    --font-size-big: 34px;\r\n    --font-size-title: 36px;;\r\n    --font-weight: 1.1;\r\n    --background-color-footer: #404040;\r\n    --font-family-text: Arial;\r\n    --margin-top-medium: 20px;\r\n    --border-radius: 16px;\r\n    --line-height: 1.4;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
