import React from 'react'
import GraciasLibroQuejasTemplate from '../components/templates/GraciasLibroQuejasTemplate'

const GraciasLibroQuejas = () => {
  return (<GraciasLibroQuejasTemplate/>

  )
}

export default GraciasLibroQuejas
