import React from 'react'
import Article from "../molecules/Article"
import ButtonIcon from '../atoms/Button'
import { width } from '@mui/system'

const GraciasLibroQuejasTemplate = () => {
  return (
    <main className='container-main' style={{height:"100px",marginBottom:"70px"}}>
        <Article title={""} styles={{width:"100%",display: "flex",alignItems: "center",justifyContent: "center"}}>
            <p className={"text-terminos-uso"}>Gracias por enviar tu consulta, queja o sugerencia. </p>
            <p className={"text-terminos-uso"} style={{textAlign:"center"}}>Te contactaremos a la brevedad.</p>
            <ButtonIcon styleButton={{maxWidth: "326px",width: "100%",fontSize: "1rem"}} to="/">Visitá nuestra web</ButtonIcon>
        </Article>
    </main>
  )
}

export default GraciasLibroQuejasTemplate
